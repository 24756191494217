import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import { InputLabel } from '@mui/material';
import { ReturnMessage } from 'components/common/ReturnMessage';
import update from 'immutability-helper';
import get from 'lodash.get';
import { useSession } from 'next-auth/client';
import { SnackbarOrigin, useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, SyntheticEvent, useMemo, useRef, useState } from 'react';

import { editableCustomType, editableOriginalType, employeeHistoryRegex, Roles, SALARY_TYPE_V1 } from '../../config';
import { getDefaultTeam } from '../../config/targetCalculatorForNewProjectsDefaultTeam';
import useSocket from '../../hooks/useSocket';
import { useOverheadStore } from '../../store/overhead/action';
import { useTeamStore } from '../../store/team/action';
import { RateType, TargetCalculatorProperties } from '../../types/components/TeamMembers';
import { forcedSignOut, getLastHistoryValue, isNumeric, round } from '../../utils';
import { ShowNumber, TeamMembers } from '../common';
import { Box } from '../common/Box';
import { NumberControl } from '../common/NumberControl';
import { FinancialResults } from '../FinancialResults';
import { Parameter } from '../FinancialResults/FinancialResultsProperties';
import { Overhead } from '../Overhead';
import { useStyles } from './styles';

const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'left' };

type RType = keyof typeof RateType;

export const TargetCalculator: FC<TargetCalculatorProperties> = ({
  employees: defaultEmployees,
  overhead: defaultOverhead,
  experiences: defaultExperiences,
  isStaging,
}) => {
  // const progress = new ProgressBar(defaultProgressBarOptions);
  const [session] = useSession();
  const { enqueueSnackbar } = useSnackbar();
  const formReference = useRef(null);
  const classes = useStyles();
  const [employees, setEmployees] = useState(defaultEmployees);
  const [experiences, setExperiences] = useState(defaultExperiences);

  const snackbarOptions = {
    anchorOrigin,
    autoHideDuration: 30000,
    preventDuplicate: false,
    className: classes.snackbar,
  };

  const [newProjectName, setNewProjectName] = useState('');
  const [reportLink, setReportLink] = useState('');
  const [status, setStatus] = useState(0);
  const [isSnackbaropen, setIsSnackbaropen] = useState(false);
  const [values, setValues] = useState({
    projectRateEU: 100,
    projectRateUS: 20,
    referralFeePercentage: 10,
    discountForClientPercentage: 5,
    targetProjectMarginPercentage: 60,
    contingency: 3,
  });

  forcedSignOut(status);
  const calculatorPrecision = isStaging ? 4 : 2;
  const userRole: string = session?.user?.roles ? session.user.roles[0] : '';
  const isDMorAdmin = [Roles.Admin, Roles.DepManager].includes(userRole as Roles);

  const fetchExperienceRates = async (): Promise<void> => {
    const result = await fetch(`/api/experienceRates`);

    forcedSignOut(result.status);
    const data = await result.json();

    setExperiences(data);
  };

  const defaultTeam = getDefaultTeam(isStaging, employees, isDMorAdmin, defaultExperiences);
  const { state: team, actions: teamActions } = useTeamStore(defaultTeam);
  const { state: overhead, actions: overheadActions } = useOverheadStore({
    type: editableOriginalType,
    value: defaultOverhead,
  });

  const fetchOverhead = async (): Promise<void> => {
    const month = new Date().toISOString().slice(0, 7);
    const result = await fetch(`/api/overheads/${month}`);

    forcedSignOut(result.status);
    const data = await result.json();

    return overheadActions.setOverhead({
      overheadType: editableOriginalType,
      value: get(data, 'overhead', 0),
    });
  };

  useSocket('realtime', async (message: Types.Controllers.Watchers.Messages) => {
    const { entity, action } = message;

    if (entity === 'employees') {
      const {
        employee: { id: teamMemberId, name, history: historyObject },
        updateDescription: updatedFields,
        isUSBased,
        isExperienceOrUSBasedChanged,
      } = message as Types.Controllers.Watchers.EmployeeMessage;
      const history = Object.entries(updatedFields)
        .filter(([key]) => key.match(employeeHistoryRegex))
        .map(([key, value]) => {
          const [, entityName, date] = key.match(employeeHistoryRegex) || [];

          return `The "${entityName}" parameter has been set to ${value} since ${date}.`;
        })
        .join(' and ');

      const salaryType = getLastHistoryValue(historyObject.salaryType, false);
      const rate = getLastHistoryValue(historyObject.rate, false);
      const rate2 = getLastHistoryValue(historyObject.rate2, false);
      const stake = getLastHistoryValue(historyObject.stake, false);
      const employmentType = getLastHistoryValue(historyObject.employmentType, false);

      const teamMemberRate =
        salaryType === SALARY_TYPE_V1 ? Number(rate) : Number(rate2) + Number(stake) / (160 * Number(employmentType));

      const employeeIndex = employees.findIndex(({ id }) => id === teamMemberId);

      if (employeeIndex >= 0) {
        setEmployees((_employees) =>
          update(_employees, {
            [employeeIndex]: (member) =>
              update(member, {
                rate: { $set: Number(teamMemberRate) },
              }),
          }),
        );
      }

      teamActions.setMemberRate({ teamMemberId, teamMemberRate });

      const hasExperience =
        isExperienceOrUSBasedChanged ||
        isUSBased ||
        (historyObject.experience && Object.keys(historyObject.experience).length > 0);

      if (hasExperience) {
        fetchExperienceRates();
      }

      enqueueSnackbar(`Employee "${name}" has been updated.\n${history}`, snackbarOptions);
    }

    if (entity === 'overheads' && overhead.type === editableOriginalType) {
      const { month, value } = message as Types.Controllers.Watchers.OverheadMessage;

      if (action === 'delete') {
        enqueueSnackbar(`${month} overhead has been deleted.`, snackbarOptions);

        await fetchOverhead();
      }

      enqueueSnackbar(`Overhead has been  has been set to ${value} since ${month}.`, snackbarOptions);

      overheadActions.setOverhead({
        value: Number(value),
      });
    }
  });

  const changeHandler = async ({ code, value }: { code: string; value: unknown }): Promise<void> => {
    try {
      setValues({ ...values, [code]: value || 0 });
    } catch (error) {
      console.error(error);
    }
  };

  const calc = useMemo(() => {
    values.contingency = values.contingency || 0;

    const teamData = team.map(
      ({
        employee,
        role,
        customRate,
        hours: { billable = 0, absorbed = 0 },
        rateType = RateType.euBillableHours,
        ...rest
      }) => {
        const salaryRate = isNumeric(`${customRate}`) ? customRate : experiences[role];
        const total = billable + absorbed;
        const salary = employee ? Number(employee.rate) * Number(total) : Number(salaryRate || 0) * Number(total);

        return {
          role,
          rate: experiences[role] || 0,
          customRate,
          hours: {
            billable,
            absorbed,
            total,
          },
          employee,
          salary,
          rateType,
          ...rest,
        };
      },
    );

    const { usBillableHours, euBillableHours, absorbedHours, estimateHours } = team.reduce(
      (accumulator, { hours: { billable = 0, absorbed = 0 }, rateType = RateType.euBillableHours }) => {
        accumulator[rateType as RType] += billable;
        accumulator.absorbedHours += absorbed;
        accumulator.estimateHours += billable + absorbed;

        return accumulator;
      },
      {
        [RateType.usBillableHours]: 0,
        [RateType.euBillableHours]: 0,
        absorbedHours: 0,
        estimateHours: 0,
      },
    );

    const estimatedDeliverySalaryCOGS = teamData.reduce((accumulator, { salary }) => {
      return accumulator + salary;
    }, 0);
    const billableHours = euBillableHours + usBillableHours;
    const euRateRevenue = euBillableHours * values.projectRateEU;
    const usRateRevenue = usBillableHours * values.projectRateUS;
    const revenue = euRateRevenue + usRateRevenue;
    const referralFee = (revenue * values.referralFeePercentage) / 100;
    const discountForClient = (revenue * values.discountForClientPercentage) / 100;
    const effectiveClientRate = revenue / estimateHours;
    const targetProjectMargin = (revenue * values.targetProjectMarginPercentage) / 100; // [Target Margin, $] = [S&F Revenue* Target profitability, %]
    const estimateOverhead = billableHours * Number(overhead.value);
    const estimateBudget = estimatedDeliverySalaryCOGS + estimateOverhead;
    const estimateCostOfHour = estimateBudget / estimateHours;
    const budgetWithoutContingency =
      revenue - (revenue * values.referralFeePercentage) / 100 - discountForClient - targetProjectMargin;
    const contingency = (budgetWithoutContingency * values.contingency) / 100;
    const budgetWithContingency = budgetWithoutContingency - contingency; // [Budget without contingency - Contingency, $]

    const projectMarginWithoutContingency = revenue - referralFee - discountForClient - estimatedDeliverySalaryCOGS;
    const projectMarginWithContingency =
      revenue - referralFee - discountForClient - contingency - estimatedDeliverySalaryCOGS;
    const companyAGMWithContingency = revenue - referralFee - discountForClient - contingency - estimateBudget;

    const companyAGMWithoutContingency = revenue - referralFee - discountForClient - estimateBudget;
    let projectMarginPercentWithoutContingency = 0;
    let companyAGMPercentWithContingency = 0;
    let companyAGMPercentWithoutContingency = 0;
    let projectMarginPercentWithContingency = 0;

    if (revenue !== 0) {
      projectMarginPercentWithoutContingency = (projectMarginWithoutContingency / revenue) * 100;
      projectMarginPercentWithContingency = (projectMarginWithContingency / revenue) * 100;
      companyAGMPercentWithContingency = (companyAGMWithContingency / revenue) * 100;
      companyAGMPercentWithoutContingency = (companyAGMWithoutContingency / revenue) * 100;
    }

    return {
      referralFee: round(referralFee),
      discountForClient: round(discountForClient),
      targetProjectMargin: round(targetProjectMargin),
      budgetWithoutContingency: round(budgetWithoutContingency),
      contingency: round(contingency),
      budgetWithContingency: round(budgetWithContingency),
      team: teamData,
      billableHours: round(billableHours),
      absorbedHours: round(absorbedHours),
      estimateHours: round(estimateHours),
      revenue: round(revenue),
      effectiveClientRate: round(effectiveClientRate || 0),
      estimatedDeliverySalaryCOGS: round(estimatedDeliverySalaryCOGS),
      estimateOverhead: round(estimateOverhead),
      estimateBudget: round(estimateBudget),
      estimateCostOfHour: round(estimateCostOfHour || 0),

      projectMarginWithContingency: round(projectMarginWithContingency),
      projectMarginPercentWithContingency: round(projectMarginPercentWithContingency),
      companyAGMWithContingency: round(companyAGMWithContingency),
      companyAGMPercentWithContingency: round(companyAGMPercentWithContingency),

      projectMarginWithoutContingency: round(projectMarginWithoutContingency),
      projectMarginPercentWithoutContingency: round(projectMarginPercentWithoutContingency),
      companyAGMWithoutContingency: round(companyAGMWithoutContingency),
      companyAGMPercentWithoutContingency: round(companyAGMPercentWithoutContingency),

      euBillableHours: round(euBillableHours),
    };
  }, [values, team, overhead.value, experiences]);

  const changeNewProjectName = (event: SyntheticEvent): void => {
    const target = event.target as HTMLTextAreaElement;

    setNewProjectName(target.value);
  };

  const generateReport = async (): Promise<void> => {
    // @ts-ignore
    const validated = formReference.current.reportValidity();

    if (validated) {
      const result = await fetch('/api/calculator/generateTargetReport', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          newProjectName,
          values,
          calc,
          overhead,
        }),
      });

      const report = await result.json();

      setReportLink(`https://docs.google.com/spreadsheets/d/${report.spreadsheetId}/edit#gid=${report.sheetId}`);
      setStatus(result.status);
      setIsSnackbaropen(true);
    }
  };

  const handleCloseSnackbar = (): void => setIsSnackbaropen(false);

  const overheadChangeHandler = (event: ChangeEvent<HTMLInputElement>): void =>
    overheadActions.setOverhead({
      overheadType: editableCustomType,
      value: Number(event.target.value),
    });

  const financialResults = {
    root: [
      {
        title: 'S&F Revenue',
        value: calc.revenue,
      },
    ],
    withoutContingency: [
      {
        title: 'Project Margin',
        percent: calc.projectMarginPercentWithoutContingency,
        value: calc.projectMarginWithoutContingency,
      },
      {
        title: 'Company AGM',
        percent: calc.companyAGMPercentWithoutContingency,
        value: calc.companyAGMWithoutContingency,
      },
    ],
    withContingency: [
      {
        title: 'Project Margin',
        percent: calc.projectMarginPercentWithContingency,
        value: calc.projectMarginWithContingency,
      },
      {
        title: 'Company AGM',
        percent: calc.companyAGMPercentWithContingency,
        value: calc.companyAGMWithContingency,
      },
    ],
  };

  const estimate: Parameter[] = [
    { title: "Effective Client's rate", value: calc.effectiveClientRate },
    { title: 'Budget without contingency', value: calc.budgetWithoutContingency },
    { title: 'Budget with contingency', value: calc.budgetWithContingency },
    {
      title: 'Overhead/hour',
      component: (
        <Overhead
          value={overhead.value || 0}
          overhead={overhead}
          fetchOverhead={fetchOverhead}
          overheadChangeHandler={overheadChangeHandler}
        />
      ),
    },
    { title: 'Estimate Hours', value: calc.estimateHours, noPrefix: true },
    { title: 'Estimated Delivery Salary (COGS)', value: calc.estimatedDeliverySalaryCOGS },
    { title: 'Estimate Overhead', value: calc.estimateOverhead },
    { title: 'Estimate Budget', value: calc.estimateBudget },
    { title: 'Estimate Cost of hour', value: calc.estimateCostOfHour },
  ];

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off" ref={formReference}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Box title="Settings">
              <Grid container spacing={2}>
                <Grid item xs={12} md={9}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="projectName">New Project Name</InputLabel>
                    <TextField
                      id="projectName"
                      required
                      value={newProjectName}
                      autoFocus
                      fullWidth
                      size="small"
                      variant="outlined"
                      onChange={changeNewProjectName}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3} />
                <Grid item xs={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="projectRateEU">Project Rate EU</InputLabel>
                    <NumberControl
                      id="projectRateEU"
                      adornmentPosition="start"
                      adornment="$"
                      eventCode="projectRateEU"
                      defaultValue={values.projectRateEU}
                      changeHandler={changeHandler}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="projectRateUS">Project Rate US</InputLabel>
                    <NumberControl
                      id="projectRateUS"
                      adornmentPosition="start"
                      adornment="$"
                      eventCode="projectRateUS"
                      defaultValue={values.projectRateUS}
                      changeHandler={changeHandler}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3} />

                <Grid item xs={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="referralFeePercentage">Referral Fee</InputLabel>
                    <NumberControl
                      id="referralFeePercentage"
                      adornmentPosition="end"
                      adornment="%"
                      eventCode="referralFeePercentage"
                      defaultValue={values.referralFeePercentage}
                      changeHandler={changeHandler}
                      hasThousandSeparator
                      noMarginBottom
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3} alignContent="center">
                  <div className={classes.settingsValue}>
                    <ShowNumber number={calc.referralFee} prefix="$" withAnimation condensed />
                  </div>
                </Grid>

                <Grid item xs={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="discountForClientPercentage">Discount for Client</InputLabel>
                    <NumberControl
                      id="discountForClientPercentage"
                      adornmentPosition="end"
                      adornment="%"
                      eventCode="discountForClientPercentage"
                      defaultValue={values.discountForClientPercentage}
                      changeHandler={changeHandler}
                      hasThousandSeparator
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3} alignContent="center">
                  <div className={classes.settingsValue}>
                    <ShowNumber number={calc.discountForClient} prefix="$" withAnimation condensed />
                  </div>
                </Grid>

                <Grid item xs={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="targetProfitability">Target Project Margin</InputLabel>
                    <NumberControl
                      id="targetProfitability"
                      adornmentPosition="end"
                      adornment="%"
                      eventCode="targetProfitability"
                      defaultValue={values.targetProjectMarginPercentage}
                      changeHandler={changeHandler}
                      hasThousandSeparator
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3} alignContent="center">
                  <div className={classes.settingsValue}>
                    <ShowNumber number={calc.targetProjectMargin} prefix="$" withAnimation condensed />
                  </div>
                </Grid>

                <Grid item xs={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="contingency">Contingency</InputLabel>
                    <NumberControl
                      id="contingency"
                      adornmentPosition="end"
                      adornment="%"
                      eventCode="contingency"
                      defaultValue={values.contingency}
                      changeHandler={changeHandler}
                      hasThousandSeparator
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3} alignContent="center">
                  <div className={classes.settingsValue}>
                    <ShowNumber number={calc.contingency} prefix="$" withAnimation condensed />
                  </div>
                </Grid>
              </Grid>
            </Box>

            <Box title="Team">
              <TeamMembers
                team={calc.team}
                employees={employees}
                onCloneTeamMember={teamActions.cloneTeamMember}
                onDeleteTeamMember={teamActions.deleteTeamMember}
                onChangeRole={teamActions.changeRole}
                onChangeEmployee={teamActions.changeEmployee}
                onChangeHours={teamActions.setHours}
                onChangeCustomRate={teamActions.setRoleCustomRate}
                onRemoveCustomRate={teamActions.removeRoleCustomRate}
                addTeamMember={teamActions.addTeamMember}
                onSetRateType={teamActions.setRateType}
                billableHours={calc.billableHours || 0}
                absorbedHours={calc.absorbedHours || 0}
                totalHours={calc.estimateHours}
                totalSalary={calc.estimatedDeliverySalaryCOGS}
                experiences={experiences}
                precision={calculatorPrecision}
                splitHours
                needsRateType
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <FinancialResults title="Financial Results" {...financialResults} />
            <FinancialResults title="Estimate" root={estimate} />

            <Button color="primary" variant="contained" className={classes.reportButton} onClick={generateReport}>
              Export to Google sheet
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isSnackbaropen}
        onClose={handleCloseSnackbar}
      >
        <ReturnMessage handleClose={handleCloseSnackbar} reportLink={reportLink} status={status} />
      </Snackbar>
    </>
  );
};
// @ts-ignore
TargetCalculator.whyDidYouRender = true;
