import Button from '@material-ui/core/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { useState } from 'react';

import { User } from './types';
import { UserListItem } from './UserListItem';
import { useUserList } from './useUserList';
interface Properties {
  selected: User[];
  departments: { _id: string; name: string }[];
}

export const UserList = ({ selected, departments }: Properties): JSX.Element => {
  const { selectedUsers, changeUserHandler, onRemoveUser, classes, addUserHandler, onUpdateUser } = useUserList({
    selected,
    departments,
  });
  const [open, setOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<{ index?: number; user?: User }>({});
  const handleClickOpen = (index: number): void => {
    setCurrentUser({ index, user: selectedUsers[index] });
    setOpen(true);
  };
  const handleClose = (): void => {
    setCurrentUser({});
    setOpen(false);
  };

  const handleRemoveUser = async (): Promise<void> => {
    if (currentUser.index !== undefined) {
      await onRemoveUser(currentUser.index);
    }
    setCurrentUser({});
    setOpen(false);
  };

  return (
    <>
      {selectedUsers.map((user, index) => (
        <UserListItem
          key={`${index}-${user.login}`}
          changeUserHandler={changeUserHandler}
          index={index}
          selectedUser={user.login}
          selectedRole={user.roles}
          selectedDepartment={user.department}
          onRemoveUser={handleClickOpen}
          onUpdateUser={onUpdateUser}
          departments={departments}
          allUsers={selectedUsers}
        />
      ))}

      <div className={classes.formControl}>
        <Button onClick={addUserHandler}>Add User</Button>
      </div>
      <Dialog open={open} keepMounted onClose={handleClose} aria-describedby="alert-dialog-remove-user">
        <DialogContent>
          <DialogContentText id="alert-dialog-remove-user">
            Are you sure you want to delete <strong>{currentUser.user?.login}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleRemoveUser}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
