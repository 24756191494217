import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

import { Roles, snackbarOptions } from '../../config';
import { forcedSignOut } from '../../utils';
import { useStyles } from './styles';
import { User } from './types';

interface Properties {
  selected: User[];
  departments: { _id: string; name: string }[];
}

export const useUserList = ({ selected, departments }: Properties) => {
  const [selectedUsers, setSelectedUsers] = useState<User[]>([{ login: '', roles: [], department: '' }]);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setSelectedUsers(selected);
  }, [selected]);

  const addUserHandler = (): void => {
    setSelectedUsers([...selectedUsers, { login: '', roles: [Roles.User], department: undefined }]);
  };

  const onRemoveUser = async (index: number): Promise<void> => {
    try {
      const result = await fetch(`/api/users/${selectedUsers[index].login}`, {
        method: 'DELETE',
      });

      forcedSignOut(result.status);

      setSelectedUsers([...selectedUsers.slice(0, index), ...selectedUsers.slice(index + 1, selectedUsers.length)]);
    } catch (error) {
      console.error(error);

      enqueueSnackbar(`Failed to remove user "${selectedUsers[index].login}"`, {
        ...snackbarOptions.error,
      });
    }
  };
  const onUpdateUser = async (index: number, value?: string[], department?: string): Promise<void> => {
    try {
      const result = await fetch(`/api/users/${selectedUsers[index].login}`, {
        method: 'PATCH',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          roles: value,
          department,
        }),
      });

      forcedSignOut(result.status);
    } catch (error) {
      console.error(error);

      enqueueSnackbar(`Failed to update user "${selectedUsers[index].login}"`, {
        ...snackbarOptions.error,
      });
    }
  };

  const changeUserHandler = async (
    value: string | null,
    index: number,
    roles: string[] = [Roles.User],
    department?: string,
  ): Promise<void> => {
    try {
      const response = await fetch(`/api/users`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          create: { value, roles, department },
          remove: selectedUsers[index].login,
        }),
      });

      forcedSignOut(response.status);

      await response.json();
      const newSelectedUsers = Object.assign([...selectedUsers], { [index]: { login: value, roles } });

      setSelectedUsers(newSelectedUsers);
    } catch (error) {
      console.error(error);

      enqueueSnackbar(`Failed to change user "${selectedUsers[index].login}" to "${value}"`, {
        ...snackbarOptions.error,
      });
    }
  };

  return {
    classes,
    addUserHandler,
    onRemoveUser,
    changeUserHandler,
    selectedUsers,
    departments,
    onUpdateUser,
  };
};
