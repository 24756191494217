import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
  return {
    toolbar: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      position: 'relative',
    },
    icon: {
      color: '#fff',
      position: 'absolute',
      right: '10px',
      top: '10px',
    },
  };
});
