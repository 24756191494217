import { useReducer } from 'react';

import { SET_OVERHEAD } from './consts';
import { Reducer } from './reducer';

export const defaultState: Types.Store.Overhead.OverheadState = {};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useOverheadStore = (initialState = defaultState) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  const setOverhead = <T extends Types.Store.Overhead.SetOverheadProperties>({ overheadType, value }: T): void =>
    dispatch({
      type: SET_OVERHEAD,
      overheadType,
      value,
    });

  return {
    actions: {
      setOverhead,
    },
    state,
  };
};
