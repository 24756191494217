import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  row: {
    fontWeight: 400,
    '& td': {
      fontSize: '16px',
      lineHeight: 1.5,
      color: '#555555',

      paddingTop: '8px',
      paddingBottom: '8px',
    },
  },
  value: {
    textAlign: 'right',
    fontWeight: 600,
  },
  bordered: {
    '& td': {
      borderTop: '1px solid #DDDDDD',
    },
  },
  settingsValue: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
    marginTop: '18px',
  },
}));
