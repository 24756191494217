import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  row: {
    fontWeight: 400,
    '& td': {
      fontSize: '16px',
      lineHeight: '20px',
      color: '#555555',

      paddingTop: '8px',
      paddingBottom: '8px',
    },
  },
  title: {
    fontWeight: 600,
  },
}));
