import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme & { drawerWidth: number }) => {
  return {
    negative: {
      color: 'red',
    },
    header: {
      marginBottom: theme.spacing(0.5),
    },
    root: {
      display: 'grid',
      gridTemplateColumns: '1fr',
    },
    condensed: {
      '& .MuiOutlinedInput-inputMarginDense, & .MuiSelect-root': {
        padding: theme.spacing(0.85),
      },

      '& label': {
        transform: 'translate(14px, 10px) scale(1)',
      },
    },
    small: {
      '& .MuiInputBase-input': {
        padding: theme.spacing(0.85),
      },
    },
    formControl: {
      display: 'flex',
    },
    reportButton: {
      textTransform: 'uppercase',
      borderRadius: '20px',
      float: 'right',
    },
    addTeamMemberButton: {
      marginTop: theme.spacing(1),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    calcInfo: {
      marginTop: theme.spacing(0),
      listStyleType: 'none',
      paddingLeft: 0,
    },
    divider: {
      margin: '1rem 0',
    },
    speedDial: {
      position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    tableHeader: {
      backgroundColor: theme.palette.grey['100'],
    },
    subHeader: {
      fontWeight: 600,
    },
    tableCellLeft: {
      paddingLeft: 0,
      paddingRight: '10px',
      paddingBottom: '10px',
      paddingTop: '10px',
    },
    tableCell: {
      padding: '10px',
      fontWeight: 600,
    },
    noBorder: {
      borderBottom: 'none',
    },
    unvisible: {
      visibility: 'hidden',
    },
    close: {
      padding: theme.spacing(0.5),
    },
    reportlink: {
      color: theme.palette.common.white,
    },
    projectName: {
      marginBottom: theme.spacing(0.5),
    },
    overheadInput: {
      padding: '0 2px',
      width: '75px',
      minWidth: '60px',
      textAlign: 'right',
      fontWeight: 700,
      lineHeight: '1.43',
      letterSpacing: '0.01071em',
      fontSize: '0.875rem',
      border: '1px solid transparent',
    },
    snackbar: {
      backgroundColor: 'green',
      [theme.breakpoints.up('sm')]: {
        maxWidth: theme.drawerWidth - 10 + 16,
        display: 'grid',
        gridTemplateColumns: `${theme.drawerWidth - 20 - 40}px 40px`,

        minWidth: theme.drawerWidth - 10 + 16,

        '& #notistack-snackbar + div': {
          marginLeft: 0,
          paddingLeft: 0,
        },
      },
    },
    financialBox: {
      backgroundColor: theme.palette.grey['100'],
      padding: '1rem',
      borderRadius: '8px',
    },
    getOverheadIconStyle: {
      padding: '0 3px 0 0',
      fontSize: '1.15rem',
    },
    estimateBox: {
      backgroundColor: theme.palette.grey['100'],
      padding: '1rem',
      borderRadius: '8px',
    },
    estimateRows: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    settingsValue: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '19px',
      marginTop: '18px',
    },
  };
});
