import { ParameterRow } from '../ParameterRow';
import { SectionParameterProps } from './SectionParameterProps';
import { useStyles } from './styles';

export const SectionParameters = ({ parameters, title }: SectionParameterProps) => {
  const classes = useStyles();

  if (!parameters.length) {
    return null;
  }

  return (
    <>
      {title && (
        <tr className={classes.row}>
          <td colSpan={3} className={classes.title}>
            {title}
          </td>
        </tr>
      )}
      {parameters.map((parameter, index) => (
        <ParameterRow key={index} parameter={parameter} index={index} />
      ))}
    </>
  );
};
