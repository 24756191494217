export const ADD_MEMBER = 'ADD_MEMBER';
export const SET_ROLE_CUSTOM_RATE = 'SET_ROLE_CUSTOM_RATE';
export const REMOVE_ROLE_CUSTOM_RATE = 'REMOVE_ROLE_CUSTOM_RATE';
export const SET_MEMBER_RATE = 'SET_MEMBER_RATE';
export const CHANGE_EMPLOYEE = 'CHANGE_EMPLOYEE';
export const SET_HOURS = 'SET_HOURS';
export const CHANGE_ROLE = 'CHANGE_ROLE';
export const CLONE_MEMBER = 'CLONE_MEMBER';
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const SET_EMPLOYEE = 'SET_EMPLOYEE';
export const SET_RATE_TYPE = 'SET_RATE_TYPE';
