import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    padding: 15,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    backgroundColor: '#fff',
    boxShadow: '1px 4px 17px -2px rgba(0,0,0,0.98)',
    border: 'none',
    outline: 'none',
    borderRadius: 3,
  },
  reportButton: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1.5),
    minWidth: 120,
    display: 'flex',
    position: 'relative',
  },
}));
