import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { useReducer } from 'react';

import { SET_SYNC_START_DATE, SET_SYNC_START_DATE_VIEW } from './consts';
import { Reducer } from './reducer';

export type SettingsState = {
  syncStartDate?: ParsableDate;
  syncStartDateView?: ParsableDate;
};

export type SetSyncStartDateProperties = {
  value: ParsableDate;
};

export type SetSettings = SetSyncStartDateProperties & {
  type: typeof SET_SYNC_START_DATE | typeof SET_SYNC_START_DATE_VIEW;
};

export type Actions = SetSettings;

export const defaultState: SettingsState = {};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useSettingsStore = (initialState = defaultState) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  const setSyncStartDate = <T extends SetSyncStartDateProperties>({ value }: T): void =>
    dispatch({
      type: SET_SYNC_START_DATE,
      value,
    });

  return {
    actions: {
      setSyncStartDate,
    },
    state,
  };
};
