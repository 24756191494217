import { Employee as EmployeeType } from '../types/components/Employee';

export type TableHeadDataType = {
  label: string;
  id: keyof EmployeeType;
};

export const tableHeadData: TableHeadDataType[] = [
  {
    label: 'Employee',
    id: 'name',
  },
  {
    label: 'Redmine Id',
    id: 'redmineId',
  },
  {
    label: 'RG Id',
    id: 'rgId',
  },
  {
    label: 'Salary Type',
    id: 'salaryType',
  },
  {
    label: 'Rate v1, $/h',
    id: 'rate',
  },
  {
    label: 'Rate v2, $/h',
    id: 'rate2',
  },
  {
    label: 'Stake',
    id: 'stake',
  },
  {
    label: 'Office',
    id: 'office',
  },
  {
    label: 'Type of employment',
    id: 'employmentType',
  },
  {
    label: 'Minimum for Rate v2',
    id: 'minimum',
  },
  {
    label: 'Practices',
    id: 'practice',
  },
  {
    label: 'Experience',
    id: 'experience',
  },
  {
    label: 'US Based',
    id: 'isUsBased',
  },
  {
    label: 'AM',
    id: 'isAm',
  },
  {
    label: 'Coordinator',
    id: 'isPm',
  },
  {
    label: 'Project Lead',
    id: 'isPl',
  },
];

export const configListOfProperties: { [key: string]: string } = {
  name: 'Employee',
  id: 'id',
  redmineId: 'Redmine Id',
  rgId: 'RG Id',
  salaryType: 'Salary Type',
  rate: 'Rate v1, $/h',
  rate2: 'Rate v2, $/h',
  stake: 'Stake',
  office: 'Office',
  employmentType: 'Type of employment',
  minimum: 'Minimun for Rate, v2',
  practice: 'Practices',
  experience: 'Experience',
  isUsBased: 'US Based',
  isAm: 'AM',
  isPm: 'Coordinator',
  isPl: 'Project Lead',
};
