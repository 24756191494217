import { Box } from '../common/Box';
import { FinancialResultsProperties } from './FinancialResultsProperties';
import { SectionParameters } from './SectionParameters';

export const FinancialResults = ({
  title,
  root = [],
  withoutContingency = [],
  withContingency = [],
}: FinancialResultsProperties): JSX.Element => {
  if (!root.length && !withoutContingency?.length && !withContingency) {
    return <Box title={title}></Box>;
  }

  return (
    <Box title={title}>
      <table width="100%">
        <tbody>
          <SectionParameters parameters={root} />
          <SectionParameters parameters={withoutContingency} title="Without Contingency" />
          <SectionParameters parameters={withContingency} title="With Contingency" />
        </tbody>
      </table>
    </Box>
  );
};
