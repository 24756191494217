import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import UpdateIcon from '@material-ui/icons/Update';
import React, { FC, useRef } from 'react';
import useColorChange from 'use-color-change';

import { colorChangeOptions, editableCustomType } from '../../config';
import { Editable } from '../common';
import { OverheadProps } from './OverheadProps';
import { useStyles } from './styles';

export const Overhead: FC<OverheadProps> = ({ value, overhead, fetchOverhead, overheadChangeHandler }) => {
  const overheadColorStyle = useColorChange(Number(value), colorChangeOptions);
  const overheadInputReference = useRef(null);
  const classes = useStyles();

  return (
    <Editable
      style={overheadColorStyle}
      editableInput={true}
      text={`$${Number(value)
        .toFixed(2)
        .replace(/\\d(?=(\\d{3})+\\.)/g, '$&,')}`}
      type="input"
      childRef={overheadInputReference}
      prefix={
        overhead.type === editableCustomType && (
          <Tooltip title="Get Overhead from the Config">
            <IconButton
              aria-label="Get Overhead from the Config"
              onClick={fetchOverhead}
              className={classes.getOverheadIconStyle}
            >
              <UpdateIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        )
      }
    >
      <input
        className={classes.overheadInput}
        ref={overheadInputReference}
        type="text"
        placeholder="Overhead"
        defaultValue={value}
        onChange={overheadChangeHandler}
      />
    </Editable>
  );
};
