import update from 'immutability-helper';

import type { Actions, SettingsState } from './action';
import { SET_SYNC_START_DATE, SET_SYNC_START_DATE_VIEW } from './consts';

export const Reducer = (state: SettingsState, action: Actions): SettingsState => {
  switch (action.type) {
    case SET_SYNC_START_DATE: {
      return update(state, {
        syncStartDate: { $set: action.value },
      });
    }
    case SET_SYNC_START_DATE_VIEW: {
      return update(state, {
        syncStartDateView: { $set: action.value },
      });
    }

    default:
      return state;
  }
};
