import clsx from 'clsx';

import { ShowNumber } from '../../common';
import { ParameterRowProps } from './ParameterRowProps';
import { useStyles } from './styles';

export const ParameterRow = ({
  parameter: { title, value, percent, component, noPrefix },
  index,
}: ParameterRowProps): JSX.Element => {
  const classes = useStyles();

  return (
    <tr
      key={index}
      className={clsx(classes.row, {
        [classes.bordered]: !!index,
      })}
    >
      <td>{title}:</td>
      {component ? (
        <td width="10%" className={classes.value}>
          {component}
        </td>
      ) : (
        <>
          {value !== undefined && (
            <td width="10%" className={classes.value}>
              <ShowNumber number={value} {...(!noPrefix && { prefix: '$' })} withAnimation condensed />
            </td>
          )}
          {percent !== undefined && (
            <td width="0%" className={classes.value}>
              {<ShowNumber number={percent} suffix="%" withAnimation condensed />}
            </td>
          )}
        </>
      )}
    </tr>
  );
};
