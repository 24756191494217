import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { useSnackbar } from 'notistack';
import React, { FC } from 'react';

import { snackbarOptions } from '../../config';
import { useSettingsStore } from '../../store/settings/action';
import { dateToString, forcedSignOut, isValidDate } from '../../utils';
import { useStyles } from './styles';

export interface SettingsProperties {
  parameters: {
    syncStartDate: ParsableDate;
  };
}

export const Settings: FC<SettingsProperties> = ({ parameters = {} }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { state: settings, actions: settingsActions } = useSettingsStore({
    syncStartDate: parameters.syncStartDate || null,
  });

  const handleSyncStartDate = async (date: Date | null): Promise<void> => {
    try {
      if (date !== null && !isValidDate(date)) {
        return;
      }

      settingsActions.setSyncStartDate({
        value: date && new Date(date).toISOString().split('T')[0],
      });

      const result = await fetch(`/api/settings`, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          setSyncStartDate: dateToString(date),
        }),
      });

      forcedSignOut(result.status);
    } catch (error) {
      console.error(error);

      enqueueSnackbar(`Failed to save Start Sync Date`, {
        ...snackbarOptions.error,
      });
    }
  };

  return (
    <div className={classes.root}>
      <form noValidate autoComplete="off">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk
            clearable
            disableFuture
            inputVariant="outlined"
            variant="dialog"
            size="small"
            label="Sync Start Date"
            value={settings.syncStartDate}
            format="MM/dd/yyyy"
            onChange={handleSyncStartDate}
          />
        </MuiPickersUtilsProvider>
      </form>
    </div>
  );
};
