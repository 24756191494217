import { DrawerProps } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import SvgIcon from '@material-ui/core/SvgIcon';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import * as React from 'react';

import { useStyles } from './styles';

interface RangePickerProperties {
  children?: DrawerProps;
  selectRange: (dateFrom: Date, dateTo: Date) => void;
}

function getThisMonthToDate() {
  const today = new Date();
  const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);

  return { dateFrom: firstDay, dateTo: today };
}

function getThisQuarterToDate() {
  const today = new Date();
  const quarter = Math.floor((today.getMonth() + 3) / 3);
  const startFullQuarter = new Date(today.getFullYear(), quarter * 3 - 3, 1);

  return { dateFrom: startFullQuarter, dateTo: today };
}

function getThisYearToDate() {
  const today = new Date();
  const firstDateThisYear = new Date(today.getFullYear(), 0, 1);

  return { dateFrom: firstDateThisYear, dateTo: today };
}

function getLastMonth() {
  const today = new Date();

  today.setMonth(today.getMonth() - 1);

  const firstDateLastMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  const lastDateLastMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  return { dateFrom: firstDateLastMonth, dateTo: lastDateLastMonth };
}

function getLastQuarter() {
  const today = new Date();
  let quarter = Math.floor((today.getMonth() + 3) / 3);

  quarter -= 1;
  const startLastQuarterDate = new Date(today.getFullYear(), quarter * 3 - 3, 1);
  const endLastQuarterDate = new Date(startLastQuarterDate.getFullYear(), startLastQuarterDate.getMonth() + 3, 0);

  return { dateFrom: startLastQuarterDate, dateTo: endLastQuarterDate };
}

function getLastYear() {
  const today = new Date();

  today.setFullYear(today.getFullYear() - 1);

  const firstDateLastYear = new Date(today.getFullYear(), 0, 1);
  const lastDateLastYear = new Date(today.getFullYear(), 11 + 1, 0);

  return { dateFrom: firstDateLastYear, dateTo: lastDateLastYear };
}

function getLast2Weeks() {
  const today = new Date();
  const prevDay = new Date(today.setDate(today.getDate() - 1));
  const twoWeeksBefore = new Date(today.setDate(today.getDate() - 13));

  return { dateFrom: twoWeeksBefore, dateTo: prevDay };
}

function getLast6Month() {
  const today = new Date();

  today.setMonth(today.getMonth() - 1);

  const firstDateLast6Month = new Date(today.getFullYear(), today.getMonth() - 5, 1);
  const lastDateLastMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  return { dateFrom: firstDateLast6Month, dateTo: lastDateLastMonth };
}

function getLast12Month() {
  const today = new Date();

  today.setMonth(today.getMonth() - 1);

  const firstDateLast12Month = new Date(today.getFullYear(), today.getMonth() - 11, 1);
  const lastDateLastMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  return { dateFrom: firstDateLast12Month, dateTo: lastDateLastMonth };
}

const ranges = [
  {
    value: 'This month to date',
    callback: getThisMonthToDate,
  },
  {
    value: 'This quarter to date',
    callback: getThisQuarterToDate,
  },
  {
    value: 'This year to date',
    callback: getThisYearToDate,
  },
  {
    value: 'Last month',
    callback: getLastMonth,
  },
  {
    value: 'Last quarter',
    callback: getLastQuarter,
  },
  {
    value: 'Last year',
    callback: getLastYear,
  },
  {
    value: 'Last 2 weeks',
    callback: getLast2Weeks,
  },
  {
    value: 'Last 6 month',
    callback: getLast6Month,
  },
  {
    value: 'Last 12 month',
    callback: getLast12Month,
  },
];

export const RangePicker: React.FC<RangePickerProperties> = ({ selectRange }) => {
  const classes = useStyles();

  return (
    <Select
      variant="outlined"
      defaultValue=""
      displayEmpty
      className={classes.select}
      classes={{ icon: classes.selectIcon, outlined: classes.selectOutlined, root: classes.selectRoot }}
      onChange={(event) => {
        const range = ranges.filter((range) => range.value === event.target.value);
        const { dateFrom, dateTo } = range[0].callback();

        selectRange(dateFrom, dateTo);
      }}
      renderValue={() => (
        <Box style={{ display: 'flex', width: 24, height: 24 }}>
          <SvgIcon className={classes.icon}>
            <CalendarMonthIcon color="action" />
          </SvgIcon>
        </Box>
      )}
    >
      {ranges.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.value}
        </MenuItem>
      ))}
    </Select>
  );
};
