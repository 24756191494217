import update from 'immutability-helper';
import invariant from 'tiny-invariant';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const projectReducer = (state: Types.Reducers.Project.State, action: Types.Reducers.Project.Actions) => {
  switch (action.type) {
    case 'CHANGE_PROJECT': {
      invariant(action.project, 'Project should not be empty');

      return {
        project: action.project,
        spentBudgetTimestamp: new Date(),
      };
    }
    case 'FETCH_SPENT_BUDGET': {
      const {
        project: { id },
      } = state;
      const { affectedProjects } = action;

      if (!affectedProjects || affectedProjects.includes(id)) {
        return update(state, {
          spentBudgetTimestamp: {
            $set: new Date(),
          },
        });
      }

      return state;
    }
    default:
      throw new Error('Invalid Action Type');
  }
};
