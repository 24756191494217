import { editableOriginalType } from 'config';
import update from 'immutability-helper';

import { SET_OVERHEAD } from './consts';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const Reducer = (state: Types.Store.Overhead.OverheadState, action: Types.Store.Overhead.Actions) => {
  switch (action.type) {
    case SET_OVERHEAD: {
      if (!action.overheadType) {
        if (state.type === editableOriginalType) {
          return update(state, {
            value: { $set: action.value },
          });
        }

        return state;
      }

      return update(state, {
        type: { $set: action.overheadType },
        value: { $set: action.value },
      });
    }

    default:
      return state;
  }
};
