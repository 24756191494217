import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React, { useEffect, useRef, useState } from 'react';

import { Roles } from '../../config';

const userRoles = [Roles.User, Roles.Admin, Roles.ProjectLead, Roles.DepManager];

export interface ConfirmationDialogRawProperties {
  keepMounted: boolean;
  value?: string[];
  departments: { _id: string; name: string }[];
  department?: string;
  open: boolean;
  onClose: (value?: string[], department?: string) => void;
}

export const ConfirmationDialogRaw = (props: ConfirmationDialogRawProperties): JSX.Element => {
  const { onClose, open, value, departments, department, ...other } = props;
  const [userRole, setUserRole] = useState(value || [Roles.User]);
  const [departmentId, setDepartmentId] = useState(department ?? departments[0]._id);
  const radioGroupReference = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!open) {
      setUserRole(userRole);
    }
  }, [userRole, open]);

  const handleEntering = (): void => {
    if (radioGroupReference.current != null) {
      radioGroupReference.current.focus();
    }
  };

  const handleCancel = (): void => {
    onClose();
  };

  const handleOk = (): void => {
    onClose(userRole, departmentId);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setUserRole([event.target.value]);
  };

  const handleChangeDepartment = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDepartmentId(event.target.value);
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      {...other}
      open={open}
    >
      <DialogTitle>System access level</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupReference}
          aria-label="userRoles"
          name="userRoles"
          value={userRole}
          onChange={handleChange}
        >
          {userRoles.map((role) => (
            <FormControlLabel value={role} key={role} control={<Radio />} label={role} />
          ))}
        </RadioGroup>
        {userRole.includes(Roles.DepManager) && (
          <RadioGroup
            sx={{ marginLeft: 5 }}
            ref={radioGroupReference}
            aria-label="departmentId"
            name="departmentId"
            value={departmentId}
            onChange={handleChangeDepartment}
          >
            {departments.map((dep) => (
              <FormControlLabel value={dep._id} key={`${dep._id}`} control={<Radio />} label={dep.name} />
            ))}
          </RadioGroup>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};
