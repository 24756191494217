import { Alert } from '@mui/material';
import NextLink from 'next/link';

export const ReturnMessage = (props: { handleClose: () => void; reportLink: string; status: number }): JSX.Element => {
  const { handleClose, reportLink, status } = props;
  const handleCloseSnackbar = (): void => handleClose();

  if (status === 403) {
    return (
      <Alert
        severity="error"
        elevation={6}
        variant="filled"
        onClose={handleCloseSnackbar}
        style={{
          color: `rgb(97, 26, 21)`,
          backgroundColor: `rgb(253, 236, 234)`,
        }}
      >
        <div>The Calculation hasn&apos;t been saved.</div>
      </Alert>
    );
  }

  return (
    <Alert
      severity="success"
      elevation={6}
      variant="filled"
      onClose={handleCloseSnackbar}
      style={{
        color: `rgb(30, 70, 32)`,
        backgroundColor: `rgb(237, 247, 237)`,
      }}
    >
      <div>The Information has been saved.</div>
      <NextLink href={reportLink}>
        <a target="_blank">{reportLink}</a>
      </NextLink>
    </Alert>
  );
};
