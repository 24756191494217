import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme & { drawerWidth: number }) => {
  return {
    root: {
      display: 'block',
      background: theme.palette.background.default,
    },
    snackbar: {
      backgroundColor: 'green',
      [theme.breakpoints.up('sm')]: {
        maxWidth: theme.drawerWidth - 10 + 16,
        display: 'grid',
        gridTemplateColumns: `${theme.drawerWidth - 20 - 40}px 40px`,

        minWidth: theme.drawerWidth - 10 + 16,

        '& #notistack-snackbar + div': {
          marginLeft: 0,
          paddingLeft: 0,
        },
      },
    },
  };
});
