import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useState } from 'react';

import { BoxProperties } from './BoxProperties';
import { useStyles } from './styles';

export const Box: React.FC<BoxProperties> = ({ title, children, collapseable = false }) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);

  const handleExpandClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <section className={classes.view}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6" className={classes.header}>
          {title}
        </Typography>
        {collapseable && (
          <IconButton onClick={handleExpandClick} aria-expanded={isOpen} style={{ width: 32, height: 32 }}>
            {isOpen ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        )}
      </div>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </section>
  );
};
