import ProgressBar from '@badrap/bar-of-progress';
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { InputLabel } from '@mui/material';
import { convertDate } from '@server/utils';
import { RangePicker } from 'components/common/RangePicker/RangePicker';
import { ReturnMessage } from 'components/common/ReturnMessage';
import update from 'immutability-helper';
import get from 'lodash.get';
import { useSession } from 'next-auth/client';
import { SnackbarOrigin, useSnackbar } from 'notistack';
import React, { ChangeEvent, FC, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import useColorChange from 'use-color-change';

import {
  colorChangeOptions,
  defaultProgressBarOptions,
  editableCustomType,
  editableOriginalType,
  employeeHistoryRegex,
  Roles,
  SALARY_TYPE_V1,
  SALARY_TYPE_V2,
} from '../../config';
import { getDefaultproject, getDefaultTeam } from '../../config/targetCaclulatorForCurrentProjectDefaultTeam';
import useSocket from '../../hooks/useSocket';
import { projectReducer } from '../../reducers/projectReducer';
import { useOverheadStore } from '../../store/overhead/action';
import { useTeamStore } from '../../store/team/action';
import theme from '../../theme';
import { Project, RateType } from '../../types/components/TeamMembers';
import {
  floatInputValue,
  forcedSignOut,
  getLastHistoryValue,
  isNumeric,
  projectBillableTypes,
  round,
} from '../../utils';
import { Editable, ShowNumber, TeamMembers } from '../common';
import { Box } from '../common/Box';
import CalendarToolbar from '../common/CalendarToolbar/CalendarToolbar';
import { NumberControl } from '../common/NumberControl';
import { FinancialResults } from '../FinancialResults';
import { Parameter } from '../FinancialResults/FinancialResultsProperties';
import { SectionParameters } from '../FinancialResults/SectionParameters';
import { Overhead } from '../Overhead/Overhead';
import { useStyles } from './styles';

const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'left' };

type RType = keyof typeof RateType;

export const TargetCalculatorForCurrentProject: FC<
  Types.Components.TargetCalculatorForCurrentProject.TargetCalculatorForCurrentProjectProperties
> = ({
  projects,
  employees: defaultEmployees,
  overhead: defaultOverhead,
  experiences: defaultExperiences,
  isStaging,
  syncStartDate,
}) => {
  let fetchProjectTimer: number;
  const { enqueueSnackbar } = useSnackbar();
  const paidBonusesInputReference = useRef(null);
  const formReference = useRef(null);
  const classes = useStyles();
  const [employees, setEmployees] = useState(defaultEmployees);
  const [experiences, setExperiences] = useState(defaultExperiences);

  const snackbarOptions = {
    anchorOrigin,
    autoHideDuration: 30000,
    preventDuplicate: false,
    className: classes.snackbar,
  };

  const [customPaidBonuses, setCustomPaidBonuses] = useState('');
  const [reportLink, setReportLink] = useState('');
  const [status, setStatus] = useState(0);
  const [isSnackbaropen, setIsSnackbaropen] = useState(false);
  const [values, setValues] = useState({
    spentBillableHours: 533,
    absorbedHours: 70,
    projectRateEU: 100,
    projectRateUS: 20,
    receivedRevenue: 45305,
    paidSalary: 0,
    projectMargin: 0,
    projectMarginPercent: 0,
    overhead: 0,
    targetProfitability: 60, // Target Project Margin
    referralFeePercentage: 10,
    discountForClientPercentage: 2,
    contingency: 10,
    paidReferral: 0,
  });

  let defaultFrom;
  let defaultTo;

  if (isStaging) {
    defaultFrom = new Date('2024-03-01');
    defaultTo = new Date('2024-03-31');
  } else {
    defaultFrom = syncStartDate ? new Date(syncStartDate) : null;
    defaultTo = new Date();
  }

  const minimumDate = defaultFrom && `${defaultFrom.toLocaleString('en-US').split(',')[0]}`;
  const [session] = useSession();
  const [range, setRange] = useState<{
    from: Date | null;
    to: Date | null;
  }>({
    from: defaultFrom,
    to: defaultTo,
  });

  forcedSignOut(status);

  const resetCustomPaidBonuses = (): void => setCustomPaidBonuses('');

  const calculatorPrecision = isStaging ? 4 : 2;
  const userRole: string = session?.user?.roles ? session.user.roles[0] : '';
  const isDMorAdmin = [Roles.Admin, Roles.DepManager].includes(userRole as Roles);

  const fetchExperienceRates = async (): Promise<void> => {
    const result = await fetch(`/api/experienceRates`);

    forcedSignOut(result.status);

    const data = await result.json();

    setExperiences(data);
  };

  const defaultTeam = getDefaultTeam(isStaging, employees, isDMorAdmin, defaultExperiences);
  const { state: team, actions: teamActions } = useTeamStore(defaultTeam);
  const { state: overhead, actions: overheadActions } = useOverheadStore(defaultOverhead);

  const fetchOverhead = async (): Promise<void> => {
    const month = new Date().toISOString().slice(0, 7);
    const result = await fetch(`/api/overheads/${month}`);

    forcedSignOut(result.status);
    const data = await result.json();

    return overheadActions.setOverhead({
      overheadType: editableOriginalType,
      value: get(data, 'overhead', 0),
    });
  };

  const defaultProject = getDefaultproject(isStaging, projects);

  const [projectStore, dispatchProject] = useReducer(projectReducer, {
    project: defaultProject,
    spentBudgetTimestamp: new Date(),
  });

  const { project, spentBudgetTimestamp } = projectStore;

  useEffect(() => {
    const progress = new ProgressBar(defaultProgressBarOptions);
    const abortController = new AbortController();
    const { signal } = abortController;

    const fetchSpentBudget = async (): Promise<null | undefined> => {
      try {
        if (!project.id) {
          return null;
        }
        progress.start();
        const from = range.from && !isNaN(range.from.valueOf()) && `from=${convertDate(range.from)}`;
        const to = range.to && !isNaN(range.to.valueOf()) && `to=${convertDate(range.to)}`;
        const query = [from, to].filter(Boolean).join('&');
        const result = await fetch(`/api/projects/${project.id}?${query}`, {
          signal,
        });

        forcedSignOut(result.status);
        const data = await result.json();
        const {
          spentBudget,
          salary,
          overhead: overheadValue,
          billableHours,
          nonBillableHours,
          revenue,
          paidReferral,
        } = data || {};

        progress.finish();

        setValues((currentValues) => ({
          ...currentValues,
          calculateTheSpentBudget: round(Number(spentBudget)),
          spentBillableHours: round(Number(billableHours)),
          absorbedHours: round(Number(nonBillableHours)),
          receivedRevenue: round(Number(revenue)),
          paidSalary: round(Number(salary)),
          projectMargin: round(Number(revenue) - Number(paidReferral) - Number(salary)),
          projectMarginPercent:
            Number(revenue) !== 0
              ? round(((Number(revenue) - Number(paidReferral) - Number(salary)) / Number(revenue)) * 100)
              : 0,
          overhead: round(Number(overheadValue)),
          paidReferral: round(Number(paidReferral)),
        }));
      } catch (error) {
        if (!signal.aborted) {
          console.error(error);
        }
      }
    };

    fetchSpentBudget();

    return () => {
      abortController.abort();
      progress.finish();
    };
  }, [project, range, spentBudgetTimestamp]);

  useSocket('realtime', async (message: Types.Controllers.Watchers.Messages) => {
    const { entity, action } = message;

    if (entity === 'projects') {
      const {
        project: { name, id: redmineId },
      } = message as Types.Controllers.Watchers.ProjectMessage;

      if (project.id === redmineId) {
        dispatchProject({ type: 'FETCH_SPENT_BUDGET' });
        enqueueSnackbar(`Project "${name}" has been updated`, snackbarOptions);
      }
    }

    if (entity === 'employees') {
      const {
        employee: { id: teamMemberId, name, history: historyObject },
        updateDescription: updatedFields,
        affectedProjects,
        isUSBased,
        isExperienceOrUSBasedChanged,
      } = message as Types.Controllers.Watchers.EmployeeMessage;
      const history = Object.entries(updatedFields)
        .filter(([key]) => key.match(employeeHistoryRegex))
        .map(([key, value]) => {
          const [, entityName, date] = key.match(employeeHistoryRegex) || [];

          return `The "${entityName}" parameter has been set to ${value} since ${date}.`;
        })
        .join(' and ');

      const salaryType = getLastHistoryValue(historyObject.salaryType, false);
      const rate = getLastHistoryValue(historyObject.rate, false);
      const rate2 = getLastHistoryValue(historyObject.rate2, false);
      const stake = getLastHistoryValue(historyObject.stake, false);
      const employmentType = getLastHistoryValue(historyObject.employmentType, false);

      const teamMemberRate =
        salaryType === SALARY_TYPE_V1 ? Number(rate) : Number(rate2) + Number(stake) / (160 * Number(employmentType));

      const employeeIndex = employees.findIndex(({ id }) => id === teamMemberId);

      if (employeeIndex >= 0) {
        setEmployees((_employees) =>
          update(_employees, {
            [employeeIndex]: (member) =>
              update(member, {
                rate: { $set: Number(teamMemberRate) },
              }),
          }),
        );
      }

      teamActions.setMemberRate({ teamMemberId, teamMemberRate });

      const hasExperience =
        isExperienceOrUSBasedChanged ||
        isUSBased ||
        (historyObject.experience && Object.keys(historyObject.experience).length > 0);

      if (hasExperience) {
        fetchExperienceRates();
      }

      if (affectedProjects?.includes(project.id)) {
        dispatchProject({ type: 'FETCH_SPENT_BUDGET' });
      }

      enqueueSnackbar(`Employee "${name}" has been updated.\n${history}`, snackbarOptions);
    }

    if (entity === 'overheads') {
      const { from, to } = range;
      const { month, value } = message as Types.Controllers.Watchers.OverheadMessage;
      const isMonthActual =
        (from === null || new Date(from).getTime() <= new Date(`${month}T00:00:00`).getTime()) &&
        (to === null || new Date(to).getTime() >= new Date(`${month}T00:00:00`).getTime());

      if (isMonthActual) {
        if (action === 'delete') {
          enqueueSnackbar(`${month} overhead has been deleted.`, snackbarOptions);

          await fetchOverhead();
        }

        overheadActions.setOverhead({
          value: Number(value),
        });

        dispatchProject({ type: 'FETCH_SPENT_BUDGET' });

        enqueueSnackbar(`Overhead has been  has been set to ${value} since ${month}.`, snackbarOptions);
      }
    }

    if (entity === 'timeEntries') {
      const projectId = get(message, 'projectId');

      if (project.id === projectId) {
        if (fetchProjectTimer) {
          window.clearTimeout(fetchProjectTimer);
        }

        fetchProjectTimer = window.setTimeout(async () => {
          dispatchProject({ type: 'FETCH_SPENT_BUDGET' });
          enqueueSnackbar(`The spent budget has been updated`, snackbarOptions);
        }, 2000);
      }
    }
  });

  const changeHandler = async ({ code, value }: { code: string; value: unknown }): Promise<void> => {
    try {
      setValues({ ...values, [code]: value || 0 });
    } catch (error) {
      console.error(error);
    }
  };

  const changeProjectHandler = async (projectData: Project): Promise<void> =>
    dispatchProject({
      type: 'CHANGE_PROJECT',
      project: projectData,
    });

  const calc = useMemo(() => {
    values.contingency = values.contingency || 0;

    const teamData = team.map(
      ({
        employee,
        role,
        customRate,
        hours: { billable = 0, absorbed = 0 },
        rateType = RateType.euBillableHours,
        ...rest
      }) => {
        const salaryRate = isNumeric(`${customRate}`) ? customRate : experiences[role];
        const total = billable + absorbed;

        let billableSalary = billable * Number(salaryRate);
        let gtSalary = absorbed * Number(salaryRate);

        if (employee) {
          const { salaryType, rateV1, rateV2, stake, employmentType } = employee || {};

          billableSalary = billable * Number(salaryType === SALARY_TYPE_V1 ? rateV1 : rateV2);
          gtSalary = absorbed * Number(salaryType === SALARY_TYPE_V1 ? rateV1 : rateV2);

          if (salaryType === SALARY_TYPE_V2) {
            const stakePart = Number(stake) / (160 * Number(employmentType));

            billableSalary += billable * stakePart;
            gtSalary += absorbed * stakePart;
          }
        }

        const salary = employee ? Number(employee.rate) * Number(total) : Number(salaryRate || 0) * Number(total);

        return {
          role,
          rate: experiences[role] || 0,
          customRate,
          hours: {
            billable,
            absorbed,
            total,
          },
          employee,
          billableSalary: round(billableSalary, 0),
          gtSalary: round(gtSalary, 0),
          salary,
          rateType,
          ...rest,
        };
      },
    );

    const effectiveRate = values.receivedRevenue / (values.spentBillableHours + values.absorbedHours);
    const agm = values.receivedRevenue - values.paidReferral - values.paidSalary - values.overhead;
    const profit = agm - Number(customPaidBonuses);

    const { usBillableHours, euBillableHours, absorbedHours, totalHours } = team.reduce(
      (accumulator, { hours: { billable = 0, absorbed = 0 }, rateType = RateType.euBillableHours }) => {
        accumulator[rateType as RType] += billable;
        accumulator.absorbedHours += absorbed;
        accumulator.totalHours += billable + absorbed;

        return accumulator;
      },
      {
        [RateType.usBillableHours]: 0,
        [RateType.euBillableHours]: 0,
        absorbedHours: 0,
        totalHours: 0,
      },
    );

    const { totalBillableSalary, totalGTSalary } = teamData.reduce(
      (accumulator, { billableSalary, gtSalary }) => {
        accumulator.totalBillableSalary += billableSalary || 0;
        accumulator.totalGTSalary += gtSalary || 0;

        return accumulator;
      },
      {
        totalBillableSalary: 0,
        totalGTSalary: 0,
      },
    );

    const phaseRevenue = euBillableHours * values.projectRateEU + usBillableHours * values.projectRateUS;
    const phaseEffectiveRate = totalHours ? phaseRevenue / totalHours : 0;
    const targetMargin = (phaseRevenue * values.targetProfitability) / 100;

    const totalSalary = teamData.reduce((accumulator, { salary }) => {
      return accumulator + salary;
    }, 0);
    const billableHours = euBillableHours + usBillableHours;
    const euRateRevenue = euBillableHours * values.projectRateEU;
    const usRateRevenue = usBillableHours * values.projectRateUS;
    const revenue = euRateRevenue + usRateRevenue;
    const referralFee = (revenue * values.referralFeePercentage) / 100;
    const discountForClient = (revenue * values.discountForClientPercentage) / 100;
    const budgetWithoutContingency =
      phaseRevenue - discountForClient - (phaseRevenue * values.referralFeePercentage) / 100 - targetMargin;
    const contingency = (budgetWithoutContingency * values.contingency) / 100;
    const budgetWithContingency = budgetWithoutContingency - contingency; // [Budget without contingency - Contingency, $]
    const estimateOverhead = billableHours * Number(overhead.value);

    const estimatedDeliverySalary = totalBillableSalary + totalGTSalary;
    const estimateBudget = totalBillableSalary + totalGTSalary + estimateOverhead;
    const estimateCostOfHour = estimateBudget / totalHours;

    const estimateCostOfBillableHour = (totalBillableSalary + estimateOverhead) / billableHours;
    const remainingHoursWeCanGiveAway = budgetWithContingency - estimateBudget; // [Budget with contingency, $-Estimate Budget, $]
    const remainingHoursWeCanGiveAwayHours = remainingHoursWeCanGiveAway / estimateCostOfBillableHour; // [Remaining hours we can give away and still hit the target, $/Estimate Cost of hour, $]
    const hoursWeCanGiveAway =
      (revenue - (revenue * values.referralFeePercentage) / 100 - contingency - estimateBudget) /
      estimateCostOfBillableHour;
    const currentProjectMarginWithoutContingency =
      phaseRevenue - (phaseRevenue * values.referralFeePercentage) / 100 - totalBillableSalary - totalGTSalary;
    const currentAGMSumWithoutContingency =
      phaseRevenue - (phaseRevenue * values.referralFeePercentage) / 100 - estimateBudget;
    const currentAGMSumWithContingency = currentAGMSumWithoutContingency - contingency;
    let currentAGMWithoutContingency = 0;
    let currentAGMWithContingency = 0;
    let currentProjectMarginPercentWithoutContingency = 0;

    if (phaseRevenue !== 0) {
      currentAGMWithContingency = (currentAGMSumWithContingency / phaseRevenue) * 100;
      currentAGMWithoutContingency = (currentAGMSumWithoutContingency / phaseRevenue) * 100;
      currentProjectMarginPercentWithoutContingency = (currentProjectMarginWithoutContingency / phaseRevenue) * 100;
    }

    const finalAGMSumWithoutContingency =
      profit + phaseRevenue - (phaseRevenue * values.referralFeePercentage) / 100 - estimateBudget;
    const finalAGMSumWithContingency = finalAGMSumWithoutContingency - contingency;

    let finalAGMWithContingency = 0;
    let finalAGMWithoutContingency = 0;

    if (revenue + values.receivedRevenue !== 0) {
      finalAGMWithContingency = (finalAGMSumWithContingency / (revenue + values.receivedRevenue)) * 100;
      finalAGMWithoutContingency = (finalAGMSumWithoutContingency / (revenue + values.receivedRevenue)) * 100;
    }

    const projectMarginWithContingency = values.projectMargin;
    // Financial results for Both phases

    // Final data without contingency
    const projectMarginWithoutContingencyForBothPhases =
      values.projectMargin +
      Number(customPaidBonuses) +
      phaseRevenue -
      referralFee -
      discountForClient -
      estimatedDeliverySalary;
    const companyAGMWithoutContingencyForBothPhases =
      profit + phaseRevenue - referralFee - discountForClient - estimateBudget;
    let projectMarginPercentWithoutContingencyForBothPhases = 0;
    let companyAGMPercentWithoutContingencyForBothPhases = 0;

    if (revenue + phaseRevenue !== 0) {
      projectMarginPercentWithoutContingencyForBothPhases =
        (projectMarginWithoutContingencyForBothPhases / (values.receivedRevenue + phaseRevenue)) * 100;
      companyAGMPercentWithoutContingencyForBothPhases =
        (companyAGMWithoutContingencyForBothPhases / (values.receivedRevenue + phaseRevenue)) * 100;
    }

    // Final data with contingency
    const projectMarginWithContingencyForBothPhases =
      projectMarginWithContingency +
      Number(customPaidBonuses) +
      phaseRevenue -
      referralFee -
      discountForClient -
      contingency -
      estimatedDeliverySalary;
    const companyAGMWithContingencyForBothPhases =
      profit + phaseRevenue - referralFee - discountForClient - contingency - estimateBudget;
    let projectMarginPercentWithContingencyForBothPhases = 0;
    let companyAGMPercentWithContingencyForBothPhases = 0;

    if (revenue + phaseRevenue !== 0) {
      projectMarginPercentWithContingencyForBothPhases =
        (projectMarginWithContingencyForBothPhases / (revenue + phaseRevenue)) * 100;
      companyAGMPercentWithContingencyForBothPhases =
        (companyAGMWithContingencyForBothPhases / (revenue + phaseRevenue)) * 100;
    }

    // Financial results for the New phase

    // New phase data without contingency
    const projectMarginWithoutContingencyForNewPhase =
      phaseRevenue - referralFee - discountForClient - estimatedDeliverySalary;
    const companyAGMWithoutContingencyForNewPhase = phaseRevenue - referralFee - discountForClient - estimateBudget;

    let projectMarginPercentWithoutContingencyForNewPhase = 0;
    let companyAGMPercentWithoutContingencyForNewPhase = 0;

    if (phaseRevenue !== 0) {
      projectMarginPercentWithoutContingencyForNewPhase =
        (projectMarginWithoutContingencyForNewPhase / phaseRevenue) * 100;
      companyAGMPercentWithoutContingencyForNewPhase = (companyAGMWithoutContingencyForNewPhase / phaseRevenue) * 100;
    }

    // New phase data with contingency
    const projectMarginWithContingencyForNewPhase =
      phaseRevenue - referralFee - discountForClient - contingency - estimatedDeliverySalary;
    const companyAGMWithContingencyForNewPhase =
      phaseRevenue - referralFee - discountForClient - contingency - estimateBudget;
    let projectMarginPercentWithContingencyForNewPhase = 0;
    let companyAGMPercentWithContingencyForNewPhase = 0;

    if (phaseRevenue !== 0) {
      projectMarginPercentWithContingencyForNewPhase = (projectMarginWithContingencyForNewPhase / phaseRevenue) * 100;
      companyAGMPercentWithContingencyForNewPhase = (companyAGMWithContingencyForNewPhase / phaseRevenue) * 100;
    }

    return {
      referralFee: round(referralFee),
      discountForClient: round(discountForClient),
      effectiveRate: round(effectiveRate),
      agm: round(agm),
      paidBonuses: round(Number(customPaidBonuses)),
      profit: round(profit),

      phaseRevenue: round(phaseRevenue),
      phaseEffectiveRate: round(phaseEffectiveRate),
      targetMargin: round(targetMargin),
      budgetWithoutContingency: round(budgetWithoutContingency),
      contingency: round(contingency),
      budgetWithContingency: round(budgetWithContingency),
      team: teamData,
      billableHours: round(billableHours),
      absorbedHours: round(absorbedHours),
      totalBillableSalary: round(totalBillableSalary),
      totalGTSalary: round(totalGTSalary),

      totalHours: round(totalHours),
      revenue: round(revenue),
      totalSalary: round(totalSalary),
      estimateOverhead: round(estimateOverhead),
      estimatedDeliverySalary: round(estimatedDeliverySalary),
      estimateBudget: round(estimateBudget),
      estimateCostOfHour: round(estimateCostOfHour),
      remainingHoursWeCanGiveAway: round(remainingHoursWeCanGiveAway),
      remainingHoursWeCanGiveAwayHours: round(remainingHoursWeCanGiveAwayHours),
      hoursWeCanGiveAway: round(hoursWeCanGiveAway),
      currentProjectMarginWithoutContingency: round(currentProjectMarginWithoutContingency),
      currentProjectMarginPercentWithoutContingency: round(currentProjectMarginPercentWithoutContingency),
      currentAGMSumWithContingency: round(currentAGMSumWithContingency),
      currentAGMSumWithoutContingency: round(currentAGMSumWithoutContingency),
      currentAGMWithContingency: round(currentAGMWithContingency),
      currentAGMWithoutContingency: round(currentAGMWithoutContingency),

      finalAGMWithContingency: round(finalAGMWithContingency),
      finalAGMSumWithContingency: round(finalAGMSumWithContingency),
      finalAGMSumWithoutContingency: round(finalAGMSumWithoutContingency),
      finalAGMWithoutContingency: round(finalAGMWithoutContingency),

      projectMarginWithoutContingencyForBothPhases: round(projectMarginWithoutContingencyForBothPhases),
      projectMarginPercentWithoutContingencyForBothPhases: round(projectMarginPercentWithoutContingencyForBothPhases),
      companyAGMWithoutContingencyForBothPhases: round(companyAGMWithoutContingencyForBothPhases),
      companyAGMPercentWithoutContingencyForBothPhases: round(companyAGMPercentWithoutContingencyForBothPhases),

      projectMarginWithContingencyForBothPhases: round(projectMarginWithContingencyForBothPhases),
      projectMarginPercentWithContingencyForBothPhases: round(projectMarginPercentWithContingencyForBothPhases),
      companyAGMWithContingencyForBothPhases: round(companyAGMWithContingencyForBothPhases),
      companyAGMPercentWithContingencyForBothPhases: round(companyAGMPercentWithContingencyForBothPhases),

      projectMarginWithoutContingencyForNewPhase: round(projectMarginWithoutContingencyForNewPhase),
      projectMarginPercentWithoutContingencyForNewPhase: round(projectMarginPercentWithoutContingencyForNewPhase),
      companyAGMWithoutContingencyForNewPhase: round(companyAGMWithoutContingencyForNewPhase),
      companyAGMPercentWithoutContingencyForNewPhase: round(companyAGMPercentWithoutContingencyForNewPhase),

      projectMarginWithContingencyForNewPhase: round(projectMarginWithContingencyForNewPhase),
      projectMarginPercentWithContingencyForNewPhase: round(projectMarginPercentWithContingencyForNewPhase),
      companyAGMWithContingencyForNewPhase: round(companyAGMWithContingencyForNewPhase),
      companyAGMPercentWithContingencyForNewPhase: round(companyAGMPercentWithContingencyForNewPhase),
    };
  }, [values, team, overhead.value, experiences, customPaidBonuses]);

  const generateReport = async (): Promise<void> => {
    // @ts-ignore
    const validated = formReference.current.reportValidity();

    if (!validated) {
      return;
    }

    const result = await fetch('/api/calculator/generateTargetCalculatorForCurrentProjectReport', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        range,
        project,
        values,
        calc,
        overhead,
      }),
    });

    const report = await result.json();

    setReportLink(`https://docs.google.com/spreadsheets/d/${report.spreadsheetId}/edit#gid=${report.sheetId}`);
    setStatus(result.status);
    setIsSnackbaropen(true);
  };

  const handleCloseSnackbar = (): void => setIsSnackbaropen(false);

  const handleDateChange = async (key: string, date: Date | null): Promise<void> => {
    if (date instanceof Date && !isNaN(date.valueOf())) {
      await setRange({
        ...range,
        [key]: date,
      });
    }
  };

  const handleRangePickerSelect = async (dateFrom: Date, dateTo: Date): Promise<void> => {
    if (dateFrom instanceof Date && !isNaN(dateFrom.valueOf()) && dateTo instanceof Date && !isNaN(dateTo.valueOf())) {
      await setRange({
        ['from']: dateFrom,
        ['to']: dateTo,
      });
    }
  };

  const overheadChangeHandler = (event: ChangeEvent<HTMLInputElement>): void =>
    overheadActions.setOverhead({
      overheadType: editableCustomType,
      value: Number(event.target.value),
    });

  const customPaidBonusesChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    setCustomPaidBonuses(floatInputValue(value));
  };

  const overheadColorStyle = useColorChange(Number(values.overhead), colorChangeOptions);

  const phaseData = [
    {
      title: 'Spent billable hours',
      value: values.spentBillableHours,
    },
    {
      title: 'Spent Absorbed Hours',
      value: values.absorbedHours,
    },
    {
      title: 'Received Revenue',
      value: values.receivedRevenue,
    },
    {
      title: 'Effective Client’s Rate',
      value: calc.effectiveRate,
    },
    {
      title: 'Paid Referral Fee',
      value: values.paidReferral,
    },
    {
      title: 'Paid Salary',
      value: values.paidSalary,
    },
    {
      title: 'Project Margin',
      value: values.projectMargin,
    },
    {
      title: 'Project Margin, %',
      percent: values.projectMarginPercent,
    },
    {
      title: 'Overhead',
      value: values.overhead,
    },
    {
      title: 'Company AGM',
      value: calc.agm,
    },
    {
      title: 'Paid bonuses',
      component: (
        <Editable
          style={overheadColorStyle}
          text={`$${Number(customPaidBonuses)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`}
          type="input"
          childRef={paidBonusesInputReference}
          hint={
            customPaidBonuses && (
              <Button onClick={resetCustomPaidBonuses} color="primary" size="small">
                Reset
              </Button>
            )
          }
          // onClose={displayOverheadMessage}
        >
          <input
            className={classes.overheadInput}
            ref={paidBonusesInputReference}
            type="text"
            placeholder="Paid bonuses"
            value={`${customPaidBonuses}`}
            onChange={customPaidBonusesChangeHandler}
          />
        </Editable>
      ),
    },
    {
      title: 'Profit',
      value: calc.profit,
    },
  ];
  const financialResultsForBothPhases = {
    withoutContingency: [
      {
        title: 'Project Margin',
        percent: calc.projectMarginPercentWithoutContingencyForBothPhases,
        value: calc.projectMarginWithoutContingencyForBothPhases,
      },
      {
        title: 'Company AGM',
        percent: calc.companyAGMPercentWithoutContingencyForBothPhases,
        value: calc.companyAGMWithoutContingencyForBothPhases,
      },
    ],
    withContingency: [
      {
        title: 'Project Margin',
        percent: calc.projectMarginPercentWithContingencyForBothPhases,
        value: calc.projectMarginWithContingencyForBothPhases,
      },
      {
        title: 'Company AGM',
        percent: calc.companyAGMPercentWithContingencyForBothPhases,
        value: calc.companyAGMWithContingencyForBothPhases,
      },
    ],
  };
  const financialResultsForNewPhase = {
    root: [
      {
        title: 'S&F Revenue',
        value: calc.phaseRevenue,
      },
    ],
    withoutContingency: [
      {
        title: 'Project Margin',
        value: calc.projectMarginWithoutContingencyForNewPhase,
        percent: calc.projectMarginPercentWithoutContingencyForNewPhase,
      },
      {
        title: 'Company AGM',
        value: calc.companyAGMWithoutContingencyForNewPhase,
        percent: calc.companyAGMPercentWithoutContingencyForNewPhase,
      },
    ],
    withContingency: [
      {
        title: 'Project Margin',
        value: calc.projectMarginWithContingencyForNewPhase,
        percent: calc.projectMarginPercentWithContingencyForNewPhase,
      },
      {
        title: 'Company AGM',
        value: calc.companyAGMWithContingencyForNewPhase,
        percent: calc.companyAGMPercentWithContingencyForNewPhase,
      },
    ],
  };

  const estimate: Parameter[] = [
    { title: 'New Phase Effective Client’s rate', value: calc.phaseEffectiveRate },
    { title: 'Budget without contingency', value: calc.budgetWithoutContingency },
    { title: 'Budget with contingency', value: calc.budgetWithContingency },
    {
      title: 'Overhead/hour',
      component: (
        <Overhead
          value={overhead.value || 0}
          overhead={overhead}
          fetchOverhead={fetchOverhead}
          overheadChangeHandler={overheadChangeHandler}
        />
      ),
    },
    { title: 'Estimate Hours', value: calc.totalHours },
    { title: 'Estimated Delivery Salary (COGS)', value: calc.totalSalary },
    { title: 'Estimate Overhead', value: calc.estimateOverhead },
    { title: 'Estimate Budget', value: calc.estimateBudget },
    { title: 'Estimate Cost of hour', value: calc.estimateCostOfHour },
  ];

  return (
    <>
      <form className={classes.root} noValidate autoComplete="off" ref={formReference}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Box title="New Phase Settings">
              <Grid container spacing={2}>
                <Grid item xs={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="projectRateEU">New Phase Rate EU</InputLabel>
                    <NumberControl
                      id="projectRateEU"
                      adornmentPosition="start"
                      adornment="$"
                      eventCode="projectRateEU"
                      defaultValue={values.projectRateEU}
                      changeHandler={changeHandler}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="projectRateUS">New Phase Rate US</InputLabel>
                    <NumberControl
                      id="projectRateUS"
                      adornmentPosition="start"
                      adornment="$"
                      eventCode="projectRateUS"
                      defaultValue={values.projectRateUS}
                      changeHandler={changeHandler}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3} />
                <Grid item xs={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="referralFeePercentage">New Phase Referral Fee</InputLabel>
                    <NumberControl
                      id="referralFeePercentage"
                      adornmentPosition="end"
                      adornment="%"
                      eventCode="referralFeePercentage"
                      defaultValue={values.referralFeePercentage}
                      changeHandler={changeHandler}
                      noMarginBottom
                      hasThousandSeparator
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3} alignContent="center">
                  <div className={classes.settingsValue}>
                    <ShowNumber number={calc.referralFee} prefix="$" withAnimation condensed />
                  </div>
                </Grid>

                <Grid item xs={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="discountForClientPercentage">Discount for Client</InputLabel>
                    <NumberControl
                      id="discountForClientPercentage"
                      adornmentPosition="end"
                      adornment="%"
                      eventCode="discountForClientPercentage"
                      defaultValue={values.discountForClientPercentage}
                      changeHandler={changeHandler}
                      hasThousandSeparator
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3} alignContent="center">
                  <div className={classes.settingsValue}>
                    <ShowNumber number={calc.discountForClient} prefix="$" withAnimation condensed />
                  </div>
                </Grid>

                <Grid item xs={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="targetProfitability">Target Project Margin</InputLabel>
                    <NumberControl
                      id="targetProfitability"
                      adornmentPosition="end"
                      adornment="%"
                      eventCode="targetProfitability"
                      defaultValue={values.targetProfitability}
                      changeHandler={changeHandler}
                      hasThousandSeparator
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3} alignContent="center">
                  <div className={classes.settingsValue}>
                    <ShowNumber number={calc.targetMargin} prefix="$" withAnimation condensed />
                  </div>
                </Grid>

                <Grid item xs={6} md={3}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="contingency">Contingency</InputLabel>
                    <NumberControl
                      id="contingency"
                      adornmentPosition="end"
                      adornment="%"
                      eventCode="contingency"
                      defaultValue={values.contingency}
                      changeHandler={changeHandler}
                      hasThousandSeparator
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3} alignContent="center">
                  <div className={classes.settingsValue}>
                    <ShowNumber number={calc.contingency} prefix="$" withAnimation condensed />
                  </div>
                </Grid>
              </Grid>
            </Box>

            <Box title="Team">
              <TeamMembers
                team={calc.team}
                employees={employees}
                onCloneTeamMember={teamActions.cloneTeamMember}
                onDeleteTeamMember={teamActions.deleteTeamMember}
                onChangeRole={teamActions.changeRole}
                onChangeEmployee={teamActions.changeEmployee}
                onChangeHours={teamActions.setHours}
                onChangeCustomRate={teamActions.setRoleCustomRate}
                onRemoveCustomRate={teamActions.removeRoleCustomRate}
                addTeamMember={teamActions.addTeamMember}
                onSetRateType={teamActions.setRateType}
                billableHours={calc.billableHours || 0}
                absorbedHours={calc.absorbedHours || 0}
                totalHours={calc.totalHours}
                totalBillableSalary={calc.totalBillableSalary || 0}
                totalGTSalary={calc.totalGTSalary || 0}
                totalSalary={calc.totalSalary}
                experiences={experiences}
                precision={calculatorPrecision}
                splitSalary
                splitHours
                needsRateType
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box title="Current Phase Data" collapseable>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel htmlFor="projects">New Project Name</InputLabel>
                    <Autocomplete
                      id="projects"
                      size="small"
                      openOnFocus
                      blurOnSelect
                      value={project}
                      options={[...projects, defaultProject]}
                      getOptionLabel={(option) => option.name}
                      getOptionSelected={(option, value) => option.name === value.name}
                      renderInput={(parameters) => <TextField {...parameters} variant="outlined" />}
                      renderOption={({ name, billableType }) => (
                        <>
                          {name}
                          {billableType && (
                            <sup className={classes.span}>&nbsp;{projectBillableTypes[`${billableType}`]}</sup>
                          )}
                        </>
                      )}
                      onChange={async (_event, value) => value && changeProjectHandler(value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <InputLabel htmlFor="from">From</InputLabel>
                    <KeyboardDatePicker
                      autoOk
                      clearable
                      disableFuture
                      inputVariant="outlined"
                      variant="dialog"
                      size="small"
                      value={range.from}
                      minDate={syncStartDate || undefined}
                      format="MM/dd/yyyy"
                      onChange={(date) => handleDateChange('from', date)}
                      ToolbarComponent={CalendarToolbar}
                      InputAdornmentProps={{
                        classes: {
                          root: classes.adornment,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <InputLabel htmlFor="to">To</InputLabel>
                    <KeyboardDatePicker
                      clearable
                      autoOk
                      disableFuture
                      inputVariant="outlined"
                      variant="dialog"
                      size="small"
                      value={range.to}
                      format="MM/dd/yyyy"
                      onChange={(date) => handleDateChange('to', date)}
                      ToolbarComponent={CalendarToolbar}
                      InputAdornmentProps={{
                        classes: {
                          root: classes.adornment,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <RangePicker selectRange={handleRangePickerSelect} />
                  </Grid>
                </Grid>
              </MuiPickersUtilsProvider>
              {minimumDate && (
                <p>
                  <a
                    href={'/tools/settings'}
                    style={{ textDecoration: 'none', color: `${theme.palette.primary.main}` }}
                  >
                    Minimal Date is: {minimumDate}
                  </a>
                </p>
              )}
              <Divider className={classes.divider} />

              <SectionParameters parameters={phaseData} />
            </Box>
            <FinancialResults title="Financial Results for Both Phases" {...financialResultsForBothPhases} />
            <FinancialResults title="Financial Results for the New Phase" {...financialResultsForNewPhase} />
            <FinancialResults title="Estimate for the New Phase" root={estimate} />

            <Button color="primary" variant="contained" className={classes.reportButton} onClick={generateReport}>
              Export to Google sheet
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isSnackbaropen}
        onClose={handleCloseSnackbar}
      >
        <ReturnMessage handleClose={handleCloseSnackbar} reportLink={reportLink} status={status} />
      </Snackbar>
    </>
  );
};
// @ts-ignore
TargetCalculatorForCurrentProject.whyDidYouRender = true;
