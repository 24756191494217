import { Employee } from 'types/components/FixBidCalculator';
import { Project } from 'types/components/TeamMembers';
import { v4 as uuid } from 'uuid';

export const getDefaultproject = (isStaging: boolean, projects: Project[]): Project =>
  isStaging ? { id: 1013, name: 'OS Hub' } : projects[0];

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/explicit-module-boundary-types
export const getDefaultTeam = (
  isStaging: boolean,
  employees: Employee[],
  isDMorAdmin: boolean,
  defaultExperiences: Record<string, number>,
) =>
  isDMorAdmin && isStaging
    ? [
        {
          id: uuid(),
          role: 'Project Lead',
          control: 'rate',
          _employee: employees.find(({ name }) => name === 'Alexander Laurie'),
          customRate: 15,
          hours: { billable: 1150, absorbed: 20 },
        },
        {
          id: uuid(),
          role: 'Middle Developer',
          control: 'rate',
          customRate: +defaultExperiences['Middle Developer'].toFixed(4),
          hours: { billable: 0 },
        },
        {
          id: uuid(),
          role: 'Coordinator',
          control: 'rate',
          customRate: 14,
          hours: { billable: 30.0, absorbed: 20 },
        },
        {
          id: uuid(),
          role: 'DevOps',
          control: 'rate',
          customRate: 13,
          hours: { billable: 55.0, absorbed: 20 },
        },
        {
          id: uuid(),
          role: 'QA',
          control: 'rate',
          customRate: 12,
          hours: { billable: 150.0, absorbed: 20 },
        },
        /* {
                  id: uuid(),
                  role: customRoleLabel,
                  control: 'rate',
                  customRate: 0,
                  hours: { billable: 0 },
                  editable: true,
                },
                {
                  id: uuid(),
                  role: 'Freelancer',
                  control: 'rate',
                  customRate: 0,
                  hours: { billable: 0 },
                },
                {
                  id: uuid(),
                  role: 'Junior Developer',
                  control: 'rate',
                  customRate: +defaultExperiences['Junior Developer'].toFixed(4),
                  hours: { billable: 0 },
                },
                {
                  id: uuid(),
                  role: 'Middle Developer',
                  control: 'rate',
                  customRate: +defaultExperiences['Middle Developer'].toFixed(4),
                  hours: { billable: 0 },
                },
                {
                  id: uuid(),
                  role: 'Senior Developer',
                  control: 'rate',
                  customRate: +defaultExperiences['Senior Developer'].toFixed(4),
                  hours: { billable: 0 },
                },
                {
                  id: uuid(),
                  role: 'Senior Developer',
                  control: 'rate',
                  customRate: +defaultExperiences['Senior Developer'].toFixed(4),
                  hours: { billable: 0 },
                },
                {
                  id: uuid(),
                  role: 'Product manager',
                  control: 'employeeSelect',
                  employee: employees[0],
                  hours: { billable: 0 },
                },
                {
                  id: uuid(),
                  role: 'Project Lead',
                  control: 'employeeSelect',
                  employee: employees[0],
                  hours: { billable: 0 },
                },
                {
                  id: uuid(),
                  role: 'Coordinator',
                  control: 'employeeSelect',
                  employee: employees[0],
                  hours: { billable: 0 },
                },
                {
                  id: uuid(),
                  role: 'DevOps',
                  control: 'employeeSelect',
                  employee: employees[0],
                  hours: { billable: 0 },
                },
                {
                  id: uuid(),
                  role: 'QA',
                  control: 'employeeSelect',
                  employee: employees[0],
                  hours: { billable: 0 },
                },
                {
                  id: uuid(),
                  role: 'Developer',
                  control: 'employeeSelect',
                  employee: employees[0],
                  hours: { billable: 0 },
                },*/
      ]
    : [];
