import { Employee } from 'types/components/FixBidCalculator';
import { v4 as uuid } from 'uuid';

import { RateType } from '../types/components/TeamMembers';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/explicit-module-boundary-types
export const getDefaultTeam = (
  isStaging: boolean,
  employees: Employee[],
  isDMorAdmin: boolean,
  defaultExperiences: Record<string, number>,
) => {
  return isDMorAdmin && isStaging
    ? [
        /* {
              id: uuid(),
              role: 'Freelancer',
              control: 'rate',
              customRate: 0,
              hours: { billable: 0 },
            },
            {
              id: uuid(),
              role: 'Junior Developer',
              control: 'rate',
              customRate: +defaultExperiences['Junior Developer'].toFixed(4),
              hours: { billable: 0 },
            },
            {
              id: uuid(),
              role: 'Middle Developer',
              control: 'rate',
              customRate: +defaultExperiences['Middle Developer'].toFixed(4),
              hours: { billable: 0 },
            },*/
        {
          id: uuid(),
          role: 'Senior Developer',
          control: 'rate',
          customRate: 40, // +defaultExperiences['Senior Developer'].toFixed(4),
          hours: { billable: 100 },
        },
        /* {
              id: uuid(),
              role: 'Senior Developer',
              control: 'rate',
              customRate: +defaultExperiences['Senior Developer'].toFixed(4),
              hours: { billable: 0 },
            },
            {
              id: uuid(),
              role: 'Product manager',
              control: 'employeeSelect',
              employee: employees[0],
              hours: { billable: 0 },
            },*/
        {
          id: uuid(),
          role: 'Project Lead',
          _employee: employees[0],
          control: 'rate',
          customRate: 15,
          _customRate: +defaultExperiences['Senior Developer'].toFixed(4),
          rateType: RateType.usBillableHours,
          hours: { billable: 200, absorbed: 150 },
        },
        /* {
              id: uuid(),
              role: 'Coordinator',
              control: 'employeeSelect',
              employee: employees[0],
              hours: { billable: 0 },
            },
            {
              id: uuid(),
              role: 'DevOps',
              control: 'employeeSelect',
              employee: employees[0],
              hours: { billable: 0 },
            },
            {
              id: uuid(),
              role: 'QA',
              control: 'employeeSelect',
              employee: employees[0],
              hours: { billable: 0 },
            },
            {
              id: uuid(),
              role: 'Developer',
              control: 'employeeSelect',
              employee: employees[0],
              hours: { billable: 0 },
            },*/
      ]
    : [];
};
