import { Tooltip } from '@material-ui/core';
import PickerToolbar from '@material-ui/pickers/_shared/PickerToolbar';
import ToolbarButton from '@material-ui/pickers/_shared/ToolbarButton';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React from 'react';

import { useStyles } from './styles';

export const CalendarToolbar = (props: ToolbarComponentProps): JSX.Element => {
  const { date, isLandscape, openView, setOpenView } = props;

  const handleChangeViewClick = (view: 'date' | 'year' | 'month' | 'hours' | 'minutes' | 'seconds') => () => {
    setOpenView(view);
  };
  const dateFormatter = (date: MaterialUiPickersDate, options: {}): string => {
    if (date) {
      const longEnUSFormatter = new Intl.DateTimeFormat('en-US', options);

      return longEnUSFormatter.format(new Date(date)).toString();
    }

    return '';
  };

  const classes = useStyles();

  return (
    <PickerToolbar className={classes.toolbar} isLandscape={isLandscape}>
      <ToolbarButton
        onClick={handleChangeViewClick('year')}
        variant="h6"
        label={dateFormatter(date, { year: 'numeric' })}
        selected={openView === 'year'}
      />
      <ToolbarButton
        onClick={handleChangeViewClick('date')}
        variant="h4"
        selected={openView === 'date'}
        label={dateFormatter(date, {
          weekday: 'short',
          month: 'short',
          day: 'numeric',
        })}
      />
      <Tooltip
        title="If you want to set a later 'From' date, go to 'Settings' and set intended 'Sync Start Date'"
        placement="top"
      >
        <InfoOutlinedIcon className={classes.icon} />
      </Tooltip>
    </PickerToolbar>
  );
};

export default CalendarToolbar;
