import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import React, { ReactElement } from 'react';
import NumberFormat from 'react-number-format';

import { defaultDecimalScale } from '../../../config';
import { NumberControlProps } from './NumberControlProps';

const getInputProps = (adornmentPosition: 'start' | 'end', adornment: string, disabled = false) => {
  const props: { [key: string]: React.ReactNode } = {};

  if (adornmentPosition === 'start') {
    props.startAdornment = <InputAdornment position="start">{adornment}</InputAdornment>;
  } else if (adornmentPosition === 'end') {
    props.endAdornment = <InputAdornment position="end">{adornment}</InputAdornment>;
  }
  if (disabled) {
    props.disabled = disabled;
  }

  return props;
};

export const NumberControl = ({
  label,
  id,
  adornmentPosition,
  adornment,
  eventCode,
  defaultValue,
  value,
  hasThousandSeparator,
  disabled,
  changeHandler,
  condensed = false,
}: NumberControlProps): ReactElement => {
  const code = `${eventCode}`;
  const inputProps = getInputProps(adornmentPosition, adornment, disabled);
  const decimalScale = condensed ? 0 : defaultDecimalScale;

  return (
    <FormControl>
      <NumberFormat
        customInput={TextField}
        thousandSeparator={hasThousandSeparator}
        decimalScale={decimalScale}
        variant="outlined"
        id={id}
        label={label}
        defaultValue={defaultValue}
        value={value}
        InputProps={inputProps}
        onValueChange={({ floatValue }) => changeHandler?.({ code, value: floatValue })}
      />
    </FormControl>
  );
};
