import isEmpty from 'lodash.isempty';
import { useCallback, useState } from 'react';

import { Employee as EmployeeType } from '../../types/components/Employee';
import { employeeSorter } from '../../utils';

export type Properties = {
  employees: EmployeeType[];
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useEmployeeList = ({ employees }: Properties) => {
  const [searchValue, setSearchValue] = useState('');
  const [paginationValue, setPaginationValue] = useState(10);
  const [page, setPage] = useState(0);
  const [sortValue, setSortValue] = useState<keyof EmployeeType>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [oneEmployeeData, setOneEmployeeData] = useState<EmployeeType>();
  const [openModal, setopenModal] = useState(false);

  const sortedEmployee = employees
    .filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()))
    .sort(employeeSorter(sortDirection, sortValue));

  const onSearchChange = useCallback((event) => {
    setSearchValue(event.target.value);
  }, []);

  const onPaginationChange = useCallback((event) => {
    setPaginationValue(event.target.value);
    setPage(0);
  }, []);

  const onPageChange = useCallback((event) => {
    setPage(event);
  }, []);

  const onSortChange = (value: keyof EmployeeType): void => {
    setSortValue(value);
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const onEmployeeClick = (id: string): void => {
    setopenModal(true);
    const employeeData = employees.find((item) => item.id === id);

    if (!isEmpty(employeeData)) {
      setOneEmployeeData(employeeData);
    }
  };

  const handleCloseModal = (): void => setopenModal(false);

  return {
    searchValue,
    onSearchChange,
    paginationValue,
    onPaginationChange,
    page,
    onPageChange,
    sortValue,
    sortDirection,
    onSortChange,
    sortedEmployee,
    onEmployeeClick,
    oneEmployeeData,
    openModal,
    handleCloseModal,
  };
};
