import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      marginTop: theme.spacing(0.75),
      marginBottom: theme.spacing(0.75),
    },
    timelineItem: {
      minHeight: theme.spacing(3),
    },
    separator: {
      minWidth: theme.spacing(4.5),
    },
    resourceGuruMonths: {
      '& $separator': {
        minWidth: theme.spacing(7.5),
      },
    },
    paper: {
      padding: '6px 16px',
    },
    tail: {
      backgroundColor: theme.palette.primary.main,
    },
    secondaryTail: {
      backgroundColor: theme.palette.secondary.main,
    },
    date: {
      margin: '0.5em 0 3px',
      height: '20px',
      display: 'flex',
      padding: '0 6px',
      zIndex: 1,
      flexWrap: 'wrap',
      fontSize: '0.75rem',
      minWidth: 20,
      boxSizing: 'border-box',
      transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      alignItems: 'center',
      fontWeight: 400,
      lineHeight: 1,
      alignContent: 'center',
      borderRadius: 10,
      flexDirection: 'row',
      justifyContent: 'center',
      backgroundColor: theme.palette.grey.A100,
      color: theme.palette.text.primary,
      whiteSpace: 'nowrap',
    },
    progressBar: {
      height: '1.25rem',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      color: '#fff',
      whiteSpace: 'nowrap',
      backgroundColor: theme.palette.secondary.main,
      transition: 'width .6s ease',
      opacity: 0.85,

      '&:hover': {
        opacity: 1,
      },
    },
    progressBarResourceGuru: {
      marginTop: 1,
    },
    progressBarTitle: {
      fontSize: 12,
      lineHeight: '1.25rem',
      height: '1.25rem',
      position: 'absolute',
      margin: '0 1rem',
      color: theme.palette.text.primary,
    },
    synced: {
      backgroundColor: theme.palette.primary.main,
    },
    timelineConnector: {
      minHeight: 20,
    },
  };
});
