import { Button, IconButton, Snackbar, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import { CloseIcon } from '@material-ui/data-grid';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Modal from '@mui/material/Modal';
import cn from 'classnames';
import { ReturnMessage } from 'components/common/ReturnMessage';
import get from 'lodash.get';
import { useState } from 'react';
import { changeValueFormat } from 'utils';

import { configListOfProperties, tableHeadData } from '../../config/employee';
import { Employee as EmployeeType } from '../../types/components/Employee';
import { useStyles } from './style';
import { useEmployeeList } from './useEmployeeList';

export interface Properties {
  employees: EmployeeType[];
}

export const EmployeeList = ({ employees }: Properties) => {
  const {
    searchValue,
    onSearchChange,
    paginationValue,
    onPaginationChange,
    page,
    onPageChange,
    sortDirection,
    onSortChange,
    sortedEmployee,
    onEmployeeClick,
    oneEmployeeData,
    openModal,
    handleCloseModal,
    sortValue,
  } = useEmployeeList({ employees });
  const classes = useStyles();
  const [isSnackbaropen, setIsSnackbarOpen] = useState(false);
  const [reportLink, setReportLink] = useState('');
  const handleCloseSnackbar = (): void => setIsSnackbarOpen(false);

  const generateEmployeeList = async () => {
    const result = await fetch('/api/config/generateEmployeeList', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        searchValue,
        sortDirection,
        sortValue,
      }),
    });

    const report = await result.json();

    setReportLink(`https://docs.google.com/spreadsheets/d/${report.spreadsheetId}/edit#gid=${report.sheetId}`);
    setIsSnackbarOpen(true);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box margin="0 0 20px 0">
            <Box component="span" margin="0 5px 0 0">
              Show
            </Box>
            <Select value={paginationValue} onChange={onPaginationChange}>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            <Box component="span" margin="0 0 0 5px">
              entries
            </Box>
          </Box>
          <TextField
            variant="outlined"
            label="Search"
            value={searchValue}
            onChange={onSearchChange}
            size="small"
            fullWidth
          />
          <Table size="small">
            <TableHead>
              <TableRow>
                {tableHeadData.map((item) => (
                  <TableCell key={item.id}>
                    <TableSortLabel direction={sortDirection} onClick={() => onSortChange(item.id)}>
                      {item.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedEmployee
                .slice(page * paginationValue, page * paginationValue + paginationValue)
                .map((item: EmployeeType) => (
                  <TableRow key={item.id} style={{ height: '60px ', cursor: 'pointer' }} hover>
                    {Object.entries(item).map(([key, value]) => {
                      if (key === 'id') {
                        return;
                      }

                      return (
                        <TableCell component="th" scope="row" key={key} onClick={() => onEmployeeClick(item.id)}>
                          {changeValueFormat(value, '')}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            style={{ overflow: 'visible' }}
            component="div"
            count={sortedEmployee.length}
            rowsPerPage={paginationValue}
            page={page}
            onChangePage={(_: unknown, value: unknown) => onPageChange(value)}
            onChangeRowsPerPage={onPaginationChange}
          />
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={generateEmployeeList}
            className={cn(classes.reportButton)}
            disabled={sortedEmployee.length === 0}
          >
            Export to Google sheet
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isSnackbaropen}
      >
        <ReturnMessage handleClose={handleCloseSnackbar} reportLink={reportLink} status={200} />
      </Snackbar>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={cn(classes.modal)} p={4} style={{ borderRadius: '15px' }}>
          <List>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="h5" style={{ color: '#2196f3' }}>
                    {get(oneEmployeeData, 'name', null)}
                  </Typography>
                }
              />
              <IconButton onClick={handleCloseModal} style={{ marginRight: '-10px' }}>
                <CloseIcon />
              </IconButton>
            </ListItem>
            <Divider />
            {oneEmployeeData && oneEmployeeData.name
              ? Object.entries(oneEmployeeData).map(([key, value]) => {
                  if (key === 'id' || key === 'name') {
                    return;
                  }
                  if (!value || value === '-') {
                    return;
                  }

                  // eslint-disable-next-line consistent-return
                  return (
                    <ListItem key={key}>
                      <ListItemText
                        primary={
                          <Typography style={{ fontWeight: 'bold' }}>{`${configListOfProperties[key]}:`}</Typography>
                        }
                      />
                      <ListItemText
                        primary={
                          <Typography style={{ textAlign: 'right' }}>{changeValueFormat(value, '')} </Typography>
                        }
                      />
                    </ListItem>
                  );
                })
              : null}
          </List>
        </Box>
      </Modal>
    </>
  );
};
