import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.primary.main,
  },
  select: {
    marginTop: theme.spacing(2.4),
    color: theme.palette.primary.main,
  },
  selectIcon: { display: 'none' },
  selectOutlined: {
    padding: '10px !important',
  },
  selectRoot: {
    borderWidth: 0,
    backgroundColor: 'transparent',
  },
}));
