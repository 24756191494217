import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useCallback, useState } from 'react';

import { Roles } from '../../config';
import { getDepNameById, validateEmail } from '../../utils';
import { useStyles } from './styles';
import { User } from './types';
import { ConfirmationDialogRaw } from './UserEditModal';
interface UserListItemProperties {
  index: number;
  changeUserHandler: (value: string | null, index: number, roles?: string[], department?: string) => void;
  selectedUser: string | null;
  onRemoveUser: (index: number) => void;
  onUpdateUser: (index: number, value?: string[], department?: string) => Promise<void>;
  selectedRole?: string[];
  selectedDepartment?: string;
  departments: { _id: string; name: string }[];
  allUsers: User[];
}

export const UserListItem = ({
  changeUserHandler,
  index,
  selectedUser,
  selectedRole,
  selectedDepartment,
  onRemoveUser,
  onUpdateUser,
  departments,
  allUsers,
}: UserListItemProperties): JSX.Element => {
  const [currentValue, setCurrentValue] = useState(selectedUser);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [userRoles, setUserRoles] = useState(selectedRole);
  const [department, setDepartment] = useState(
    selectedRole?.includes(Roles.DepManager) ? getDepNameById(departments, selectedDepartment) : '',
  );
  const [departmentId, setDepartmentId] = useState(
    selectedRole?.includes(Roles.DepManager) ? selectedDepartment : undefined,
  );

  const onEditUser = (): void => {
    setOpen(true);
  };

  const handleClose = (newRoleValue?: string[], newDepartment?: string): void => {
    setOpen(false);
    if (newRoleValue) {
      setUserRoles(newRoleValue);
      if (newDepartment) {
        setDepartmentId(newRoleValue?.includes(Roles.DepManager) ? newDepartment : undefined);
        setDepartment(newRoleValue?.includes(Roles.DepManager) ? getDepNameById(departments, newDepartment) : '');
      }
      if (['Admin', 'User'].includes(newRoleValue[0])) {
        onUpdateUser(index, newRoleValue, '');
      } else {
        onUpdateUser(index, newRoleValue, newDepartment);
      }
    }
  };

  const handleChange = useCallback((event) => {
    setCurrentValue(event.target.value);
  }, []);

  const onChangeUser = useCallback(
    (event) => {
      if (!event.target.value) {
        setErrorText('Required');
        setIsError(true);

        return;
      }

      if (!validateEmail(event.target.value)) {
        setErrorText('Not correct format');
        setIsError(true);

        return;
      }

      const isExistsDuplicate = allUsers.find((user) => user.login.toLowerCase() === event.target.value.toLowerCase());

      if (!!isExistsDuplicate && event.target.value !== selectedUser) {
        setErrorText('This email is exists in DB');
        setIsError(true);

        return;
      }

      if (isError) {
        setErrorText('');
        setIsError(false);
      }

      changeUserHandler(`${event.target.value}`, index, userRoles, department);
    },
    [allUsers, selectedUser, isError, changeUserHandler, index, userRoles, department],
  );

  return (
    <div className={classes.formControl} key={`user-${index}`}>
      <TextField
        label="Email"
        variant="outlined"
        value={currentValue}
        error={isError}
        helperText={errorText}
        type="email"
        required
        fullWidth
        onChange={handleChange}
        onBlur={onChangeUser}
      />
      <TextField value={userRoles} inputProps={{ readOnly: true }} />
      {department && <TextField value={department} inputProps={{ readOnly: true }} />}
      <IconButton aria-label="edit" onClick={onEditUser} title="Edit User">
        <EditIcon fontSize="small" />
      </IconButton>
      <IconButton aria-label="delete" onClick={() => onRemoveUser(index)} title="Remove User">
        <DeleteIcon fontSize="small" />
      </IconButton>
      <ConfirmationDialogRaw
        keepMounted
        open={open}
        onClose={handleClose}
        value={userRoles}
        departments={departments}
        department={departmentId}
      />
    </div>
  );
};
