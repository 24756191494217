import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  getOverheadIconStyle: {
    padding: '0 3px 0 0',
    fontSize: '1.15rem',
  },
  overheadInput: {
    padding: '0 2px',
    width: '75px',
    minWidth: '60px',
    textAlign: 'right',
    fontWeight: 700,
    lineHeight: '1.43',
    letterSpacing: '0.01071em',
    fontSize: '0.875rem',
    border: '1px solid transparent',
  },
}));
