export const ValueTypes = {
  string: 'string',
  number: 'number',
  boolean: 'boolean',
  symbol: 'symbol',
  bigint: 'bigint',
  function: 'function',
  date: 'date',
  object: 'object',
  array: 'array',
  null: 'null',
  undefined: 'undefined',
  unknown: 'unknown',
} as const;

type ValueType = typeof ValueTypes[keyof typeof ValueTypes];

const __getObjectType = (value: object | null): ValueType => {
  if (value === null) {
    return ValueTypes.null;
  }
  if (Array.isArray(value)) {
    return ValueTypes.array;
  }
  if (value instanceof Date) {
    return ValueTypes.date;
  }

  return ValueTypes.object;
};

export const getValueType = (value: unknown): ValueType => {
  switch (typeof value) {
    case 'string':
      return ValueTypes.string;
    case 'number':
      return ValueTypes.number;
    case 'boolean':
      return ValueTypes.boolean;
    case 'function':
      return ValueTypes.function;
    case 'undefined':
      return ValueTypes.undefined;
    case 'symbol':
      return ValueTypes.symbol;
    case 'bigint':
      return ValueTypes.bigint;
    case 'object':
      return __getObjectType(value);
    default:
      return ValueTypes.unknown;
  }
};

export const isEmpty = <T = unknown>(value: unknown): value is undefined | null | '' | {} | T[] =>
  value === null ||
  value === undefined ||
  (getValueType(value) === ValueTypes.string && value === '') ||
  (getValueType(value) === ValueTypes.object && value === {}) ||
  (getValueType(value) === ValueTypes.array && value === []);

export const convertDate = (date: Date): string => {
  const yyyy = date.getFullYear().toString();
  const mm = (date.getMonth() + 1).toString();
  const dd = date.getDate().toString();

  const mmChars = mm.split('');
  const ddChars = dd.split('');

  return `${yyyy}-${mmChars[1] ? mm : `0${mmChars[0]}`}-${ddChars[1] ? dd : `0${ddChars[0]}`}`;
};
