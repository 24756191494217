import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    view: {
      backgroundColor: theme.palette.grey['100'],
      display: 'flex',
      flexDirection: 'column',
      padding: '24px',
      borderRadius: '20px',
      marginBottom: '20px',
    },
    header: {
      marginBottom: theme.spacing(2.5),
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: 1.6,
      color: '#212121',
    },
  };
});
